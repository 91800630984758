import * as React from "react"
import Header, { ThemeProvider, useTheme } from "../Components/Header"
import { Redirect, Route, Switch } from "react-router-dom"
import Logo from "src/img/ecosuite_logo.png"
import Sidebar from "./Sidebar"
import ListMyDocuments from "./ListMyDocuments"
import DocumentUploadDashboard from "./DocumentUpload"
import { H1 } from "src/components/ui/typography"

const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-background">
      <Header logo={Logo} />
      <div className="tw-flex ">
        <div className="lg:tw-w-[320px]">
          <Sidebar />
        </div>
        <main className="tw-flex-grow tw-p-6">{children}</main>
      </div>
    </div>
  )
}

const Profile = () => {
  return (
    <div>
      <H1>Profile content goes here</H1>
    </div>
  )
}

const Onboarding = () => {
  return (
    <ThemeProvider>
      <Layout>
        <div>
          <Route exact path="/onboarding" render={() => <Redirect to="/onboarding/upload" />} />
          <Route path="/onboarding/upload" component={DocumentUploadDashboard} />
          <Route path="/onboarding/view" component={ListMyDocuments} />
          <Route path="/onboarding/profile" component={Profile} />
        </div>
      </Layout>
    </ThemeProvider>
  )
}

export default Onboarding

import React from "react"

import EcosuiteModule from "@common/module/EcosuiteModule"

import ConnectivityService from "./ConnectivityService"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import EcosuiteComponent, { EcosuiteComponentError, Error } from "@common/EcosuiteComponent"

import ConnectivityOverView from "./views/ConnectivityDashboardView"
import ConnectivityMapView from "./views/ConnectivityMapView"
import ConnectivityListView from "./views/ConnectivityListView"
import ConnectivityDashboardProjectView from "./views/ConnectivityDashboardProjectView"
import Logger from "@common/Logger"
import i18n from "src/i18n"

const { t } = i18n
export default class ConnectivityModule extends EcosuiteModule {
  constructor(props) {
    super(props, "connectivity")
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadConnectivity(this.getExclusiveRange())
    this.loadDevices()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    // We recalculate the date range for "All Time" so that the graphs can be updated if necessary
    this.recalculateAllTimeRange(prevProps)

    if (
      JSON.stringify(this.getProjects(this.props).map((project) => project.code)) !== JSON.stringify(this.getProjects(prevProps).map((project) => project.code)) ||
      (prevProps.loadTime && this.props.loadTime !== prevProps.loadTime)
    ) {
      this.loadConnectivity(this.getExclusiveRange())
    }
  }

  selectRange(rangeName, customRange) {
    let range = super.selectRange(rangeName, customRange)
    this.loadConnectivity(range)
  }

  getProjects(props) {
    return props.project ? [props.project] : props.projects
  }

  loadConnectivity(range) {
    this.setState({
      connectivity: undefined,
    })
    const projects = this.getProjects(this.props)
    ConnectivityService.getConnectivity(projects, range)
      .then((response) => {
        const projectIds = projects.map((project) => project.code)
        const latestProjectIds = this.getProjects(this.props).map((project) => project.code)
        if (this.isRangeCurrent(range) && JSON.stringify(projectIds) === JSON.stringify(latestProjectIds)) {
          this.setStateIfMounted({
            connectivity: response,
            projects: projects,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((error) => {
        Logger.error(error)
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({ connectivity: new EcosuiteComponentError(error) })
        }
      })
  }

  loadDevices() {
    this.setState({
      devices: undefined,
    })
    ConnectivityService.getDevices()
      .then((response) => {
        this.setStateIfMounted({
          devices: response,
        })
      })
      .catch((error) => {
        this.setStateIfMounted({ devices: new EcosuiteComponentError(error) })
      })
  }

  renderProjectView() {
    if (this.isContentError(this.state.connectivity)) {
      return <Error error={this.state.connectivity.getError()} />
    }
    if (this.isContentError(this.state.devices)) {
      return <Error error={this.state.devices.getError()} />
    }
    return (
      <ConnectivityProjectViews
        view={this.state.projectView}
        layout={this.state.layout}
        groups={this.props.groups}
        projects={this.state.projects}
        project={this.props.project}
        connectivity={this.state.connectivity}
        devices={this.state.devices}
        range={this.getExclusiveRange()}
        aggregation={DateRangeUtils.getAggregateForRange(this.getExclusiveRange())}
        selectRange={this.selectRange}
      />
    )
  }

  renderModuleView() {
    if (this.isContentError(this.state.connectivity)) {
      return <Error error={this.state.connectivity.getError()} />
    }
    if (this.isContentError(this.state.devices)) {
      return <Error error={this.state.devices.getError()} />
    }

    return (
      <ConnectivityPortfolioViews
        view={this.state.moduleView}
        layout={this.state.layout}
        groups={this.props.groups}
        projects={this.state.projects}
        connectivity={this.state.connectivity}
        devices={this.state.devices}
        range={this.getExclusiveRange()}
        aggregation={DateRangeUtils.getAggregateForRange(this.getExclusiveRange())}
        selectProject={this.selectProject}
        selectRange={this.selectRange}
      />
    )
  }
}
class ConnectivityProjectViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "overview":
        return <ConnectivityDashboardProjectView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}` + this.props.view }} />
    }
  }
}

class ConnectivityPortfolioViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "overview":
        return <ConnectivityOverView {...this.props} />
      case "map":
        return <ConnectivityMapView {...this.props} />
      case "list":
        return <ConnectivityListView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}` + this.props.view }} />
    }
  }
}

import * as React from "react"
import { useOrganizations } from "../services/organization"
import { DataType, SortingMode } from "ka-table/enums"
import { Table } from "ka-table"
import "./table-theme.scss"
import Icon from "@common/display/Icon"
import { useHistory } from "react-router-dom"
import { Button } from "@aws-amplify/ui-react"
import { SignOut, VerifySuperAdmin } from "./SuAdmin"
import moment from "moment"

export default function SuOrganizationList() {
  const { data, isLoading, isError, isFetching } = useOrganizations()
  const history = useHistory()

  React.useEffect(() => {
    VerifySuperAdmin()
  }, [])

  if (isError) return <div>Error fetching organizations.</div>

  if (isLoading) return <div>Loading...</div>

  return (
    <div className="custom-theme-demo" style={{ padding: "8px" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{ backgroundColor: "grey" }}
          onClick={() => {
            SignOut()
          }}
        >
          Logout
        </Button>
      </div>
      <h2 style={{ textAlign: "center", marginTop: "8px" }}>Organizations</h2>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px" }}>
        <Button
          variation="primary"
          onClick={() => {
            history.push("/su-admin/organization/create")
          }}
        >
          Create New Organization
        </Button>
      </div>
      <Table
        columns={[
          { key: "id", title: "Id", dataType: DataType.String },
          { key: "name", title: "Name", dataType: DataType.String },
          { key: "created", title: "Created", dataType: DataType.Date },
          { key: "editColumn", width: 80 },
        ]}
        data={data?.Items}
        rowKeyField={"id"}
        sortingMode={SortingMode.Single}
        childComponents={{
          cellText: {
            content: ({ column, rowData }) => {
              if (column.key === "editColumn") {
                return (
                  <Icon
                    icon="edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/su-admin/organization/${rowData.id}`, rowData)
                    }}
                  />
                )
              }
              if (column.key === "created") {
                return <>{moment(rowData.created).format("LLL")}</>
              }
            },
          },
        }}
      />
    </div>
  )
}

import AppConfig from "src/AppConfig"
import Schemas from "@common/Schemas"
import UserAdminService from "../UserAdminService"

import { COLUMNS, COLUMN_NAMES } from "@dashboard/finance/views/ReportView"

const UserTypeSchema = {
  getUserTypeUISchema() {
    const config = AppConfig.getConfig()
    const amsModules = {
      "ui:order": ["event", "energy", "demandresponse", "connectivity", "finance", "data", "process"],
    }

    // Add UI Schema for each AMS module
    Object.keys(config.ams.modules).map((moduleId) => {
      amsModules[moduleId] = {
        portfolioViews: {
          "ui:widget": "userTypeSelector",
          "ui:options": {
            inline: true,
          },
        },

        projectViews: {
          "ui:widget": "userTypeSelector",
          "ui:options": {
            inline: true,
          },
        },
      }

      if (moduleId === "finance") {
        amsModules[moduleId].irrControls = {
          columnToggles: {
            "ui:widget": "userTypeSelector",
            "ui:options": {
              inline: true,
            },
          },
        }
      }

      if (moduleId === "data") {
        amsModules[moduleId].recordTypes = {
          compliance: {
            "ui:widget": "userTypeSelector",
            "ui:options": {
              inline: true,
            },
          },
          energy: {
            "ui:widget": "userTypeSelector",
            "ui:options": {
              inline: true,
            },
          },
          financial: {
            "ui:widget": "userTypeSelector",
            "ui:options": {
              inline: true,
            },
          },
        }
      }
    })

    // Add UI Schema for each Econode module
    const econodeModules = {}
    Object.keys(config.econode.modules).map((moduleId) => {
      econodeModules[moduleId] = {
        portfolioViews: {
          "ui:widget": "userTypeSelector",
          "ui:options": {
            inline: true,
          },
        },

        projectViews: {
          "ui:widget": "userTypeSelector",
          "ui:options": {
            inline: true,
          },
        },
      }
    })

    return {
      defaultGroups: {
        "ui:widget": "userGroupSelector",
      },
      modules: {
        ams: amsModules,
        econode: econodeModules,
      },
    }
  },

  /**
   * Take full userTypeSchema and split it into two pages based on `userTypeSchema` and `userGroupSchema`
   * @returns {jsonFormSchema[]} [userTypeSchema, userGroupSchema]
   */
  async getUserTypeSchema() {
    const config = AppConfig.getConfig()
    // TO-DO Add Custom Field to implement Select All Record Types / Select All Record SubTypes
    const recordTypes = await loadRecordTypes()

    return UserAdminService.getUserTypeSchema().then((schema) => {
      const formSchemas = []

      // Make a copy of the schema response to become userTypeSchema
      // { ...schema } and Object.assign() copy by reference but we need to copy by value since we're deleting properties
      const userTypeSchema = JSON.parse(JSON.stringify(schema))

      // Remove default groups from the first page's schema
      delete userTypeSchema.properties.defaultGroups

      // We extend the schema to include app specific config
      userTypeSchema.dependencies = {
        application: {
          oneOf: [
            {
              properties: {
                application: {
                  enum: ["ams"],
                },
                modules: {
                  type: "object",
                  title: "Applications",
                  description: "Configure the default application permissions that users of this type have access to.",
                  properties: {
                    ams: {
                      type: "object",
                      title: "AMS",
                      description: "Configure the views that users of this type can access in each of the AMS modules that they have access to. User specific restrictions may further limit options.",
                      properties: getModules("ams", config.ams.modules, recordTypes),
                    },
                    econode: {
                      type: "object",
                      title: "Econode",
                      description: "Configure the views that users of this type can access in each of the AMS modules that they have access to. User specific restrictions may further limit options.",
                      properties: getModules("econode", config.econode.modules),
                    },
                  },
                },
              },
            },
            {
              properties: {
                application: {
                  enum: ["econode"],
                },
                modules: {
                  type: "object",
                  title: "Applications",
                  description: "Configure the default application permissions that users of this type have access to.",
                  properties: {
                    econode: {
                      type: "object",
                      title: "Econode",
                      description: "Configure the views that users of this type can access in each of the AMS modules that they have access to. User specific restrictions may further limit options.",
                      properties: getModules("econode", config.econode.modules),
                    },
                  },
                },
              },
            },
          ],
        },
      }

      // Push in first page of form
      formSchemas.push(userTypeSchema)

      // Make a copy of the schema response to become userGroupSchema
      // { ...schema } and Object.assign() copy by reference but we need to copy by value since we're deleting properties
      const userGroupSchema = JSON.parse(JSON.stringify(schema))

      // Remove the application selection from the second page's schema
      delete userGroupSchema.properties.application

      // Push in second page of form
      formSchemas.push(userGroupSchema)

      return formSchemas
    })
  },
}

/**
 * @param {string} - app name
 * @param {*} modules - app modules
 * @param {jsonFormSchema} recordTypes - formatted record schema
 * @returns {jsonFormSchema} userType form schema
 */
const getModules = (application, modules, recordTypes) => {
  const properties = {}

  Object.keys(modules).forEach((moduleId) => {
    const module = modules[moduleId]
    properties[moduleId] = {
      type: "object",
      title: module.name,
      properties: {
        portfolioViews: {
          type: "array",
          title: "Portfolio Views",
          items: {
            type: "string",
            enum: module.portfolio.views.map((view) => view.id),
            enumNames: module.portfolio.views.map((view) => view.name),
          },
          uniqueItems: true,
        },
        projectViews: {
          type: "array",
          title: "Project Views",
          items: {
            type: "string",
            enum: module.project.views.map((view) => view.id),
            enumNames: module.project.views.map((view) => view.name),
          },
          uniqueItems: true,
        },
      },
    }

    /**
     * Only add these field entries to AMS
     */
    if (application === "ams") {
      /**
       * Additional `finance` checkboxes
       */
      if (moduleId === "finance") {
        properties[moduleId].properties.irrControls = loadIRRControlSchema()
      }

      /**
       * Additional `data` checkboxes
       */
      if (moduleId === "data") {
        properties[moduleId].properties.recordTypes = recordTypes
      }
    }
  })

  return properties
}

/**
 * @returns {jsonFormSchema} formatted record schema
 */
const loadRecordTypes = async () => {
  return Schemas.getRecordSchema().then((schema) => {
    let typeHierarchy = {}
    schema.properties.recordType.enum.forEach((recordType, idx) => {
      let recordTypeDependency = schema.dependencies.recordType.oneOf.find((type) => type.properties.recordType.enum[0] === recordType)
      let enums = recordTypeDependency.properties.recordSubType.enum
      let enumNames = recordTypeDependency.properties.recordSubType.enumNames

      typeHierarchy[recordType] = {
        type: "array",
        title: schema.properties.recordType.enumNames[idx],
        items: { type: "string", enum: enums, enumNames: enumNames },
        uniqueItems: true,
        default: [], // Ensures that the recordTypes stored on the userType exist
      }
    })

    return {
      type: "object",
      title: "Record Types",
      description: "- Configure the records that users of this type will be able to access inside of the AMS data module",
      properties: typeHierarchy,
    }
  })
}

/**
 * Generates the form schema for the `irrControls` sub form
 */
const loadIRRControlSchema = () => {
  return {
    type: "object",
    title: "IRR Controls",
    description: "- Configure how the IRR operates",
    properties: {
      columnToggles: {
        type: "array",
        title: "The column toggles that will be displayed for this user type",
        items: { type: "string", enum: COLUMNS, enumNames: COLUMN_NAMES },
        uniqueItems: true,
        default: COLUMNS,
      },
    },
  }
}

export default UserTypeSchema

import React from "react"
import { Input } from "reactstrap"
import { create, all } from "mathjs"

const math = create(all)

export function irradianceWidget(props) {
  const readonly = props.formContext.readonly
  return (
    <div>
      <div className="form-group field field-object irradiance-input">
        <Input
          type="number"
          className="form-group field field-string"
          value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
          required={props.required}
          onChange={(event) => {
            props.onChange(event.target.value)
          }}
          readOnly={readonly || props.readonly}
          disabled={props.disabled}
        />
        <span className="units">(kWh/m2/day)</span>
      </div>
      <div className="form-group field field-object irradiance-input">
        <Input
          type="number"
          className="form-group field field-string"
          value={!isNaN(props.value) ? math.round(props.value * props.options.days, 0) : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
          required={props.required}
          onChange={(event) => {
            if (!isNaN(event.target.value)) {
              props.onChange(math.round(event.target.value / props.options.days, 2))
            }
          }}
          readOnly={readonly || props.readonly}
          disabled={props.disabled}
        />
        <span className="units">(kWh/m2/month)</span>
      </div>
    </div>
  )
}

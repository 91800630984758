import API from "@common/API"
import Logger from "@common/Logger"
import { getErrorMessage } from "@common/SharedComponentUtils"
import { StoredProjectChecklist } from "@dashboard/process/views/checklists/ChecklistView"

/**
 * Get the project checklist schema.
 * @param code - The code.
 * @param checklistType - The checklist type.
 */
export const getProjectChecklistSchema = (code: string, checklistType: string): Promise<Schema> => {
  const uri = `/schema/project-checklist/${code}/${checklistType}`
  return API.get(uri)
    .then((response) => {
      const data = response as { schema: unknown }
      return data.schema
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to get '${uri}': ${getErrorMessage(err)}` })
    })
}

/**
 * Get all project checklists of code.
 * @param code - The code.
 */
export const getCodesProjectChecklist = (code: string): Promise<StoredProjectChecklist[]> => {
  const uri = `/checklist/code/project-checklist/${code}`
  return API.get(uri)
    .then((response) => {
      const data = response as { checklists: StoredProjectChecklist[] }
      return data.checklists
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to get '${uri}': ${getErrorMessage(err)}` })
    })
}

/**
 * Overwrite a project checklist.
 * @param code - The code.
 * @param checklistType - The checklist type.
 * @param schema - The updated project checklist schema.
 */
export const overwriteProjectChecklist = (code: string, checklistType: string, schema: unknown): Promise<StoredProjectChecklist> => {
  const uri = `/checklist/project-checklist/${code}/${checklistType}`
  return API.put(uri, schema)
    .then((response) => {
      const data = response as { checklist: StoredProjectChecklist }
      return data.checklist
    })
    .catch((err) => {
      Logger.error(err)
      return Promise.reject({ message: `Unable to put '${uri}': ${getErrorMessage(err)}` })
    })
}

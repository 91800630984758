import React from "react"

import EcosuiteComponent, { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"
import Logger from "@common/Logger"
import EcosuiteModule from "@common/module/EcosuiteModule"
import EnergyService from "@dashboard/energy/EnergyService"
import EventService from "../event/EventService"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import Aggregations from "@common/Aggregations"
import ReportView from "./views/ReportView"
import ReportProjectView from "./views/ReportProjectView"
import BillingView from "./views/BillingProjectView"
import RecordPaymentsProjectView from "./views/RecordPaymentsProjectView"
import FinanceDashboardView from "./views/FinanceDashboardView"
import FinanceMapView from "./views/FinanceMapView"
import FinanceEventsView from "./views/FinanceEventsView"
import SourcesUsesView from "./views/SourcesUsesView"
import ProFormaInputsProjectView from "./views/ProFormaInputsProjectView"
import CashFlowPaymentLogsView from "./views/CashFlowPaymentLogsView"

import "./FinanceModule.css"
import PortfolioCashFlowPaymentLogsView from "./views/portfolio-cash-flow/PortfolioCashFlowPaymentLogsView"
import SettingsService from "@admin/settings/SettingsService"
import ConsumptionCostsProjectView from "./views/consumption-costs/ConsumptionCosts"
import FinancialModelView from "./views/model/FinancialModelView"
import i18n from "src/i18n"
import moment from "moment"
import ExternalModelView from "@dashboard/finance/views/external-model/ExternalModelView"
import EpcBidSheetView from "@dashboard/finance/views/epc-bid-sheet/EpcBidSheetView"
import Rates from "../finance/views/Rates"
import SmartsheetSheetView from "@dashboard/finance/views/smartsheet-sheet/SmartsheetSheetView"

const { t } = i18n

export default class FinanceModule extends EcosuiteModule {
  constructor(props) {
    super(props, "finance")

    this.state.projectDiscountRate = ""

    this.loadEvents = this.loadEvents.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadEvents(this.getExclusiveRange())
    this.fetchFormData()

    let range = this.getExclusiveRange()

    const aggregate = this.getAggregateForRange(range)

    this.loadPreviousConsumptionCost(range, aggregate)
    this.loadConsumptionCost(range)
    this.loadEnergyReadings(range, aggregate)
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    if (this.props.loadTime !== prevProps.loadTime) {
      this.loadEvents(this.getExclusiveRange())
    }
  }

  async fetchFormData() {
    return await SettingsService.getParamsFromPaths("Settings").then((formData) => {
      const paramValue = formData.Settings.find((setting) => setting.Name === "/Settings/DISCOUNT_RATE")
      this.setState({ projectDiscountRate: paramValue.Value })
    })
  }

  getAggregateForRange(range) {
    let aggregate = DateRangeUtils.getAggregateForRange(range)
    if (range.diff("days", true) <= 1) {
      aggregate = Aggregations.FifteenMinute
    }
    return aggregate
  }

  selectRange(rangeName, customRange) {
    let range = super.selectRange(rangeName, customRange)

    const aggregate = this.getAggregateForRange(range)

    this.loadEvents(range)
    this.loadPreviousConsumptionCost(range, aggregate)
    this.loadConsumptionCost(range)
    this.loadEnergyReadings(range, aggregate)
  }

  loadPreviousConsumptionCost(range, aggregate) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      actualRange: undefined,
      previousRange: undefined,
      previousConsumptionCost: undefined,
    })

    const now = moment()
    if (range.start.isAfter(now)) {
      // We set to null to indicate that there is no actual/previous data to load for the selected dates
      this.setStateIfMounted({
        actualRange: null,
        previousRange: null,
        previousConsumptionCost: null,
      })
    } else {
      let actualRange = moment.range(range.start, range.end.isAfter(now) ? now.add(1, aggregate).startOf(aggregate) : range.end)
      let rangeLength = actualRange.end - actualRange.start
      let previousRange = moment.range(actualRange.start - rangeLength, actualRange.end - rangeLength)

      EnergyService.getConsumptionCost(previousRange)
        .then((response) => {
          if (this.isRangeCurrent(range)) {
            this.setStateIfMounted({
              actualRange: actualRange,
              previousRange: previousRange,
              previousConsumptionCost: response,
            })
          }
        })
        .catch((err) => {
          this.setStateIfMounted({
            actualRange: actualRange,
            previousRange: previousRange,
            previousConsumptionCost: new EcosuiteComponentError(err),
          })
        })
    }
  }

  loadConsumptionCost(range) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      consumptionCost: undefined,
    })

    EnergyService.getConsumptionCost(range)
      .then((response) => {
        if (this.isRangeCurrent(range)) {
          this.setStateIfMounted({
            consumptionCost: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({
          consumptionCost: new EcosuiteComponentError(err),
        })
      })
  }

  loadEnergyReadings(range, aggregate) {
    // Clear the existing state to make it clear an update is occuring
    this.setStateIfMounted({
      readings: undefined,
    })

    EnergyService.getEnergyReadings(range)
      .then((response) => {
        if (this.isRangeCurrent(range, aggregate)) {
          this.setStateIfMounted({
            readings: response,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        if (this.isRangeCurrent(range, aggregate)) {
          this.setStateIfMounted({
            readings: new EcosuiteComponentError(err),
          })
        }
      })
  }

  loadEvents(range) {
    if (this.props.groups.indexOf("event") < 0) {
      Logger.debug("Not loading events as permissions not enabled")
      return
    }

    if (!range) {
      range = this.getExclusiveRange()
    }
    // Clear the existing state to make it clear an update is occuring
    this.setState({
      events: undefined,
    })
    EventService.listEvents(range, "finance")
      .then((data) => {
        if (this.isRangeCurrent(range)) {
          let events = data.events.sort((a, b) => {
            if (a.startDate === b.startDate) {
              return a.cause.localeCompare(b.cause)
            }
            return a.startDate.localeCompare(b.startDate)
          })
          this.setStateIfMounted({
            events: events,
          })
        } else {
          Logger.debug(`Ignoring out of date response for range: ${range}`)
        }
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({
          events: new EcosuiteComponentError(err),
        })
      })
  }

  renderProjectView() {
    let project = this.props.project
    if (!project) {
      return <Loading />
    }

    return (
      <FinanceProjectViews
        loadTime={this.props.loadTime}
        view={this.state.projectView}
        groups={this.props.groups}
        projects={[this.props.project]}
        project={project}
        range={this.getExclusiveRange()}
        selectProject={this.selectProject}
        events={this.isContentValid(this.state.events) ? this.state.events.filter((event) => event.path.startsWith("/" + project.code)) : this.state.events}
        actions={{ loadEvents: this.loadEvents }}
        restrictions={this.props.restrictions}
        projectDiscountRate={this.state.projectDiscountRate}
        rangeName={this.state.rangeName}
        customRange={this.getCustomRange()}
        actualRange={this.state.actualRange}
        previousRange={this.state.previousRange}
        selectRange={this.selectRange}
        readings={this.isContentValid(this.state.readings) ? this.state.readings.projects[project.code] : this.state.readings}
        datumsRange={this.isContentValid(this.state.consumptionCost) ? this.state.consumptionCost.range : this.state.datums}
        datumsAggregation={this.isContentValid(this.state.consumptionCost) ? this.state.consumptionCost.aggregation : "unknown"}
        projectCosts={this.isContentValid(this.state.consumptionCost) ? this.state.consumptionCost.projects[project.code] : this.state.consumptionCost}
        previousConsumptionCost={this.isContentValid(this.state.previousConsumptionCost) ? this.state.previousConsumptionCost.projects[project.code] : this.state.previousConsumptionCost}
        showConsumption={true} //this.isConsumptionVisible()
        showStorage={this.isStorageVisible()}
      />
    )
  }

  renderModuleView() {
    return (
      <FinanceViews
        loadTime={this.props.loadTime}
        view={this.state.moduleView}
        groups={this.props.groups}
        projects={this.props.projects}
        range={this.getExclusiveRange()}
        events={this.state.events}
        selectProject={this.selectProject}
        actions={{ loadEvents: this.loadEvents }}
        restrictions={this.props.restrictions}
        projectDiscountRate={this.state.projectDiscountRate}
      />
    )
  }
}

/**
 * Portfolio Finance Views
 */
class FinanceViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "list":
      case "overview":
        return <FinanceDashboardView {...this.props} />
      case "report":
        return <ReportView projects={this.props.projects} restrictions={this.props.restrictions} />
      case "sourcesUses":
        return <SourcesUsesView {...this.props} />
      case "map":
        return <FinanceMapView {...this.props} />
      case "events":
        return <FinanceEventsView {...this.props} />
      case "payments":
        return <PortfolioCashFlowPaymentLogsView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}: ` + this.props.view }} />
    }
  }
}

/**
 * Project Finance Views
 */
class FinanceProjectViews extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state.proFormaVersion = {}
  }

  getViews() {}

  renderContent() {
    switch (this.props.view) {
      case "rates": {
        return <Rates projectCode={this.props.project.code} />
      }
      case "proFormaInputs":
        return (
          <ProFormaInputsProjectView
            {...this.props}
            proFormaVersion={this.state.proFormaVersion[this.props.project.code]}
            actions={{
              ...this.props.actions,
              setProFormaVersion: (version, callback) => {
                this.setStateIfMounted({ proFormaVersion: { ...this.state.proFormaVersion, [this.props.project.code]: version } }, callback)
              },
            }}
          />
        )
      case "sourcesUses":
        return (
          <SourcesUsesView
            {...this.props}
            proFormaVersion={this.state.proFormaVersion[this.props.project.code]}
            actions={{
              ...this.props.actions,
              setProFormaVersion: (version, callback) => {
                this.setStateIfMounted({ proFormaVersion: { ...this.state.proFormaVersion, [this.props.project.code]: version } }, callback)
              },
            }}
          />
        )
      case "cashflows":
      case "report":
        return (
          <ReportProjectView
            {...this.props}
            restrictions={this.props.restrictions}
            proFormaVersion={this.state.proFormaVersion[this.props.project.code]}
            actions={{
              ...this.props.actions,
              setProFormaVersion: (version, callback) => {
                this.setStateIfMounted({ proFormaVersion: { ...this.state.proFormaVersion, [this.props.project.code]: version } }, callback)
              },
            }}
          />
        )
      case "billing":
        return <BillingView {...this.props} />
      case "financial-model":
        return <FinancialModelView {...this.props} />
      case "external-financial-model":
        return <ExternalModelView code={this.props.project?.code} />
      case "epc-bid-sheet":
        return <EpcBidSheetView code={this.props.project?.code} />
      case "smartsheet-sheet":
        return <SmartsheetSheetView code={this.props.project?.code} />
      case "consumptions":
        return <ConsumptionCostsProjectView {...this.props} />
      // Records of Payment Logs
      case "records":
        return <RecordPaymentsProjectView {...this.props} />
      case "payments":
        return <CashFlowPaymentLogsView {...this.props} />
      case "events":
        return <FinanceEventsView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}: ` + this.props.view }} />
    }
  }
}

import React, { createContext, useContext, useState, useEffect, ReactNode } from "react"
import { Sun, Moon } from "lucide-react"
import { Button } from "src/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu"

type Theme = "light" | "dark"

interface ThemeContextType {
  theme: Theme
  toggleTheme: (theme: Theme) => void
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

interface ThemeProviderProps {
  children: ReactNode
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>("light")

  const applyTheme = (newTheme: Theme) => {
    document.documentElement.classList.toggle("dark", newTheme === "dark")
  }

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") as Theme | null
    const systemPrefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches
    const initialTheme = savedTheme || (systemPrefersDark ? "dark" : "light")
    setTheme(initialTheme)
    applyTheme(initialTheme)
    localStorage.setItem("theme", initialTheme)
  }, [])

  const toggleTheme = (newTheme: Theme): void => {
    setTheme(newTheme)
    localStorage.setItem("theme", newTheme)
    applyTheme(newTheme)
  }

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>
}

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider")
  }
  return context
}

interface Props {
  logo?: string
  orgName?: string
}

const Header: React.FC<Props> = ({ logo, orgName }) => {
  const { theme, toggleTheme } = useTheme()

  return (
    <header className="tw-border-b tw-bg-background tw-text-foreground">
      <div className="tw-flex tw-h-20 tw-items-center tw-justify-between tw-px-4">
        <div className="tw-flex tw-items-center tw-ml-8 lg:tw-ml-0">
          {logo ? (
            <img src={logo} style={{ height: "64px" }} alt="Logo" />
          ) : (
            <span className="tw-text-2xl tw-font-bold">{orgName}</span>
          )}
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="tw-relative">
              <Sun
                className={`tw-h-[1.2rem] tw-w-[1.2rem] tw-transition-all ${theme === "dark" ? "tw-scale-0" : "tw-scale-100"}`}
              />
              <Moon
                className={`tw-absolute tw-h-[1.2rem] tw-w-[1.2rem] tw-transition-all ${theme === "dark" ? "tw-scale-100" : "tw-scale-0"}`}
              />
              <span className="tw-sr-only">Toggle theme</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="menu-items">
            <DropdownMenuItem onClick={() => toggleTheme("light")}>Light</DropdownMenuItem>
            <DropdownMenuItem onClick={() => toggleTheme("dark")}>Dark</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  )
}

export default Header

import { create, all } from "mathjs"
const math = create(all)

var DatumUtils = {
  /**
   * Groups data based on the supplied assets
   * @param {*} assets An array of assets that the data in the assetMap is to be grouped by using the each assets code.
   * @param {*} assetMap The map of data to be grouped, keyed by asset code.
   */
  groupDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, asset) => {
        let assetCode = asset.code
        let assetEntry = assetMap[assetCode]
        if (assetEntry && assetEntry.aggregatedTotals) {
          Object.keys(assetEntry.aggregatedTotals).forEach((dateKey) => {
            let datum = assetEntry.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[assetCode] = {
                generation: datum.generation,
                forecastGeneration: datum.forecastGeneration,
                forecast12Generation: datum.forecast12Generation,
                forecast24Generation: datum.forecast24Generation,
                forecast48Generation: datum.forecast48Generation,
                normalisedGeneration: datum.normalisedGeneration,
                consumption: datum.consumption,
                forecastConsumption: datum.forecastConsumption,
                export: datum.export,
                generationReading: datum.generationReading,
                consumptionReading: datum.consumptionReading,
                storage: datum.storage,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                generation: datum.generation,
                forecastGeneration: datum.forecastGeneration,
                forecast12Generation: datum.forecast12Generation,
                forecast24Generation: datum.forecast24Generation,
                forecast48Generation: datum.forecast48Generation,
                consumption: datum.consumption,
                forecastConsumption: datum.forecastConsumption,
                export: datum.export,
                generationReading: datum.generationReading,
                consumptionReading: datum.consumptionReading,
                storage: datum.storage,
                max: {
                  generation: {
                    asset: assetDatums.code,
                    watts: datum.generation,
                  },
                  consumption: {
                    asset: assetDatums.code,
                    watts: datum.consumption,
                  },
                },
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].generation += datum.generation
              datums[dateKey].forecastGeneration += datum.forecastGeneration
              datums[dateKey].forecast12Generation += datum.forecast12Generation
              datums[dateKey].forecast24Generation += datum.forecast24Generation
              datums[dateKey].forecast48Generation += datum.forecast48Generation
              datums[dateKey].consumption += datum.consumption
              datums[dateKey].forecastConsumption += datum.forecastConsumption
              datums[dateKey].export += datum.export
              datums[dateKey].generationReading += datum.generationReading
              datums[dateKey].consumptionReading += datum.consumptionReading
              datums[dateKey].storage += datum.storage

              if (datum.generation > datums[dateKey].max.generation.watts) {
                datums[dateKey].max.generation = {
                  asset: assetEntry.code,
                  watts: datum.generation,
                }
              }
              if (datum.consumption > datums[dateKey].max.consumption.watts) {
                datums[dateKey].max.consumption = {
                  asset: assetEntry.code,
                  watts: datum.consumption,
                }
              }

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[assetCode] = {
                generation: datum.generation,
                forecastGeneration: datum.forecastGeneration,
                forecast12Generation: datum.forecast12Generation,
                forecast24Generation: datum.forecast24Generation,
                forecast48Generation: datum.forecast48Generation,
                normalisedGeneration: datum.normalisedGeneration,
                consumption: datum.consumption,
                forecastConsumption: datum.forecastConsumption,
                export: datum.export,
                generationReading: datum.generationReading,
                consumptionReading: datum.consumptionReading,
                storage: datum.storage,
              }
            }
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },

  groupConsumptionCostDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, project) => {
        let code = project.code
        let asset = assetMap[code]
        if (asset && asset.consumptionCost.aggregatedTotals) {
          Object.keys(asset.consumptionCost.aggregatedTotals).forEach((dateKey) => {
            let datum = asset.consumptionCost.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[code] = {
                meteredCost: datum.meteredCost,
                demandCharge: datum.demandCharge,
                peakDemandCharge: datum.peakDemandCharge,
                fixedCost: datum.fixedCost,
                demandCost: datum.demandCost,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                meteredCost: datum.meteredCost,
                demandCharge: datum.demandCharge,
                peakDemandCharge: datum.peakDemandCharge,
                fixedCost: datum.fixedCost,
                demandCost: datum.demandCost,
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].meteredCost = math.add(datums[dateKey].meteredCost, datum.meteredCost)
              datums[dateKey].demandCharge = math.add(datums[dateKey].demandCharge, datum.demandCharge)
              datums[dateKey].peakDemandCharge =
                datums[dateKey].peakDemandCharge || datum.peakDemandCharge
                  ? math.add(
                      datums[dateKey].peakDemandCharge ? datums[dateKey].peakDemandCharge : 0,
                      datum.peakDemandCharge ? datum.peakDemandCharge : 0,
                    )
                  : null
              datums[dateKey].fixedCost = math.add(datums[dateKey].fixedCost, datum.fixedCost)

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[code] = {
                meteredCost: datum.meteredCost,
                demandCharge: datum.demandCharge,
                peakDemandCharge: datum.peakDemandCharge,
                demandCost: datum.demandCost,
                fixedCost: datum.fixedCost,
              }
            }
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },

  groupPredictedConsumptionDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, project) => {
        let code = project.code
        let asset = assetMap[code]
        if (asset && asset.aggregatedTotals) {
          Object.keys(asset.aggregatedTotals).forEach((dateKey) => {
            let datum = asset.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[code] = {
                predictedConsumption: datum.predictedConsumption,
                targetConsumption: datum.targetForecast,
                historicConsumption: datum.historicForecast,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                predictedConsumption: datum.predictedConsumption,
                targetConsumption: datum.targetForecast,
                historicConsumption: datum.historicForecast,
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].predictedConsumption += datum.predictedConsumption
              datums[dateKey].targetConsumption += datum.targetForecast
              datums[dateKey].historicConsumption += datum.historicForecast

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[code] = {
                predictedConsumption: datum.predictedConsumption,
                targetConsumption: datum.targetForecast,
                historicConsumption: datum.historicForecast,
              }
            }
          })
        }
        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },

  groupExpectedGenerationDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, project) => {
        let code = project.code
        let asset = assetMap[code]
        if (asset && asset.aggregatedTotals) {
          Object.keys(asset.aggregatedTotals).forEach((dateKey) => {
            let datum = asset.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[code] = {
                expectedGeneration: datum.expectedGeneration,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                expectedGeneration: datum.expectedGeneration,
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].expectedGeneration += datum.expectedGeneration

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[code] = {
                expectedGeneration: datum.expectedGeneration,
              }
            }
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },

  groupPredictedGenerationDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, project) => {
        let code = project.code
        let asset = assetMap[code]
        if (asset && asset.aggregatedTotals) {
          Object.keys(asset.aggregatedTotals).forEach((dateKey) => {
            let datum = asset.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[code] = {
                predictedGeneration: datum.predictedGeneration,
                simpleForecastGeneration: datum.simpleForecastGeneration,
                irradianceForecastGeneration: datum.irradianceForecastGeneration,
                acEnergyForecastGeneration: datum.acEnergyForecastGeneration,
                Rise: asset.Rise,
                Set: asset.Set,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                predictedGeneration: datum.predictedGeneration,
                simpleForecastGeneration: datum.simpleForecastGeneration,
                irradianceForecastGeneration: datum.irradianceForecastGeneration,
                acEnergyForecastGeneration: datum.acEnergyForecastGeneration,
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].predictedGeneration += datum.predictedGeneration
              datums[dateKey].simpleForecastGeneration += datum.simpleForecastGeneration
              datums[dateKey].irradianceForecastGeneration += datum.irradianceForecastGeneration
              datums[dateKey].acEnergyForecastGeneration += datum.acEnergyForecastGeneration

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[code] = {
                predictedGeneration: datum.predictedGeneration,
                simpleForecastGeneration: datum.simpleForecastGeneration,
                irradianceForecastGeneration: datum.irradianceForecastGeneration,
                acEnergyForecastGeneration: datum.acEnergyForecastGeneration,
                Rise: asset.Rise,
                Set: asset.Set,
              }
            }
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },
}

export default DatumUtils

import { useMutation, useQuery, useQueryClient } from "react-query"
import { get, post, put } from "../setup"

interface User {
  id: string
}

export const getUsersForLimitedAdminApi = () => get<Array<User>>("/usersLimitedAdmin")
export const createUserForLimitedAdminApi = (body: User) => post<User, User>("/createUserForLimitedAdmin", body)

export function useUsersForLimitedAdmin() {
  return useQuery({
    queryKey: ["usersLimitedAdmin"],
    queryFn: async () => {
      const response = await getUsersForLimitedAdminApi()
      return response
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  })
}

export function usecreateUserForLimitedAdmin() {
  const client = useQueryClient()
  return useMutation(createUserForLimitedAdminApi, {
    onSuccess: () => client.invalidateQueries(["usersLimitedAdmin"]),
  })
}
interface UserOrganization {
  id: string
  userId: string
  organizationId: string
  name: string
  solarNetworkCredentials?: {
    host?: string
    sshHost?: string
    wssHost?: string
    secret?: string
    token?: string
  }
  settings?: {
    projectMilestoneRecipients?: string[]
    logoUrl?: string
    salesforceUrl?: string
    qgisUrl?: string
    isPublic?: boolean
    slackXbobToken?: string
  }
}

export const getUserOrganizationApi = (userId: string) =>
  get<{ organization: UserOrganization }>(`/organizations/user/${userId}`)

export function useUserOrganization(userId: string | undefined) {
  return useQuery({
    queryKey: ["userOrganization", userId],
    queryFn: async () => {
      const response = await getUserOrganizationApi(userId!)
      return response
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: Boolean(userId),
  })
}

export const updateOrganizationApi = (organization: UserOrganization) =>
  put<UserOrganization, UserOrganization>("/organizations", organization)

export function useUpdateOrganization() {
  const client = useQueryClient()
  return useMutation(updateOrganizationApi, {
    onSuccess: () => client.invalidateQueries(["userOrganization"]),
  })
}

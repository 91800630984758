import React from "react"
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, ReferenceLine, Cell, Legend } from "recharts"
import moment from "moment"

import prettyBytes from "src/pretty-bytes"

import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import GraphUtils from "@common/utils/GraphUtils"
import Aggregations from "@common/Aggregations"
import DateRangeUtils from "@common/utils/DateRangeUtils"

class ConnectivityTotalGraph extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.zoomDateRange = this.zoomDateRange.bind(this)
  }

  zoomDateRange(selection) {
    // We don't zoome
    if (selection && this.props.connectivity.aggregation !== Aggregations.Hour) {
      let milliseconds = moment(selection.activeLabel).valueOf()
      if (milliseconds > 0) {
        const zoomRange = DateRangeUtils.getDateRange(moment(milliseconds), this.props.connectivity.aggregation)
        this.props.selectRange("custom", zoomRange)
      }
    }
  }

  renderContent() {
    let connectivity = this.props.connectivity
    if (connectivity) {
      let data = []
      this.props.projects.forEach((project) => {
        let projectConnectivity = connectivity.projects[project.code]
        Object.values(projectConnectivity.sites).forEach((siteConnectivity) => {
          siteConnectivity.data.forEach((entry, idx) => {
            if (!data[idx]) {
              data[idx] = {
                date: entry.date,
                bytes: entry.bytes,
              }
            } else {
              data[idx].bytes += entry.bytes
            }
          })
        })
      })

      let expectedDailyData = this.props.projects.reduce((total, project) => {
        let projectExpectedDailyData = Object.values(project.sites).reduce((total, site) => {
          return (total += site.expectedDailyData ? site.expectedDailyData : 0)
        }, 0)
        return (total += projectExpectedDailyData)
      }, 0)

      return (
        <ResponsiveContainer width="100%" height={270}>
          <BarChart data={data} margin={{ top: 0, right: 30, bottom: 5, left: 50 }} onClick={this.zoomDateRange}>
            <XAxis
              dataKey="date"
              tickFormatter={(time) => {
                return GraphUtils.dateFormat(time, this.props.connectivity.range, this.props.connectivity.aggregation)
              }}
            />
            <YAxis tickFormatter={prettyBytes} />
            <ReferenceLine y={expectedDailyData} stroke="#0EB6DB" />
            <Bar dataKey="bytes" fill={GRAPH_COLORS[0]}>
              {data.map((entry, index) => (
                <Cell fill={entry.bytes < expectedDailyData ? "orange" : GRAPH_COLORS[0]} key={index} />
              ))}
            </Bar>
            <Tooltip
              wrapperStyle={{ color: "#000" }}
              // eslint-disable-next-line no-unused-vars
              formatter={(value, name, props) => {
                return prettyBytes(value)
              }}
              labelFormatter={(label) => {
                return GraphUtils.formatDateTooltip(label, this.props.connectivity.range, this.props.connectivity.aggregation)
              }}
            />
            <Legend />
          </BarChart>
        </ResponsiveContainer>
      )
    } else {
      return <Loading />
    }
  }
}

export default ConnectivityTotalGraph

import React, { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import i18n from "src/i18n"
import Icon from "@common/display/Icon"
import { Auth } from "aws-amplify"
import aws from "aws-sdk"
import { useHistory } from 'react-router-dom';
import RecordService from "../RecordService"

import Markdown from "marked-react";
import { Loading } from "@common/EcosuiteComponent"

const { t } = i18n

/**
 * The job result.
 */
interface JobResult {
  [fileName: string]: {
    classification: string,
    description: string
    record: string,
  }
}

const DistilleryWorkStatusSucceeded = 2

const DistillPopover = ({ fileKey, fileName, distilleryMap, ready, waiting }: { fileKey: string, fileName: string, distilleryMap: Map<string, any>, ready: boolean, waiting: boolean }): JSX.Element | null => {
  const history = useHistory()

  const [showModal, setShowModal] = useState(false)
  const [resultRecord, setResultRecord] = useState<any>(null)
  const [startingJob, setStartingJob] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  };

  if (!resultRecord && distilleryMap && distilleryMap.has(fileKey)) {
    const resultJob = distilleryMap.get(fileKey)

    if (resultJob.status == DistilleryWorkStatusSucceeded) {
      const resultObj = JSON.parse(resultJob.result)
      setResultRecord(Object.values(resultObj)[0])
    }
  }

  const contentStyle: any = {
    paddingLeft: "20px",
    borderLeft: "3px dotted",
    whiteSpace: "preserve",
    overflow: "scroll",
    height: "600px"
  }

  let contentJsx: any = null

  if (resultRecord) {
    const description: string = resultRecord["description"]
    contentJsx = <Markdown value={description} />
  } else {
    contentJsx = <Loading />
  }

  function fixFormattedFields(input: any) {
    for (const [k, v] of Object.entries(input)) {

      const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}/gmi
      const emailRegex = /.+@.+/gmi

      // If the field is a date or email, we can't specify that formatting
      // directly to the OpenAI API right now. When the LLM doesn't know the
      // answer, it will give back badly formatted dates/emails.
      if (typeof (v) == "string") {
        if (/date/gmi.test(k) && !(dateRegex.test(v))) {
          input[k] = undefined
        }
        else if (/email/gmi.test(k) && !(emailRegex.test(v))) {
          input[k] = undefined
        }
      } else if (typeof (v) == "object") {
        fixFormattedFields(v)
      }
    }
  }

  return (
    <div>
      <Button
        style={{ padding: "5px" }}
        color="link"
        size="sm"
        onClick={async () => {
          if (ready && resultRecord != null) {
            toggleModal()
          } else if (!ready && !waiting && !startingJob) {
            // Don't allow multiple jobs to be started (if the user clicks twice)
            setStartingJob(true)

            // If we're not ready, and we're not waiting, give the user the
            // ability to start a job now

            const s3Url = await RecordService.s3GetPresignedUrl(fileKey)
            const formData = await RecordService.createFormData(s3Url, fileName)
            const job = await RecordService.distill(formData)

            setStartingJob(false)
          }
        }}
        title={"Distill"}
      >
        <Icon icon="science" />
      </Button>

      <Modal isOpen={showModal} toggle={toggleModal} size={"lg"}>
        <ModalHeader toggle={toggleModal}>Distill File</ModalHeader>
        <ModalBody>
          <h1> <span className="material-icons md-48" style={{ fontSize: "36px" }}>science</span> Distillery</h1>
          <hr />
          <span>
            <span>
              <blockquote style={contentStyle}>
                {contentJsx}
              </blockquote>
              <cite>&mdash; Stevie, Ecosuite</cite>
            </span>
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color={"primary"} onClick={async () => {
            const record = JSON.parse(resultRecord['record'])
            record['recordType'] = resultRecord['recordType']
            record['recordSubType'] = resultRecord['recordSubType']

            fixFormattedFields(record)
            console.log(record)

            const recordString = JSON.stringify(record, null, "")
            const data = btoa(recordString)

            history.push(`/data/record/${data}`)
          }} >
            Distill
          </Button>{" "}
          <Button color="secondary" onClick={() => setShowModal(false)}>
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default DistillPopover

import * as React from "react"
import { Organization, useCreateOrganization, useOrganizations } from "../services/organization"
import { Button } from "@aws-amplify/ui-react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useHistory } from "react-router-dom"
import NiceModal from "@ebay/nice-modal-react"
import { RootUserEmailPrompt } from "@admin/organizations/CreateOrganization"
import { toast } from "react-toastify"
import OrganizationFormFields from "./OrganizationFormFields"
import { VerifySuperAdmin } from "./SuAdmin"
import { formatForm } from "./helpers"
import SuAdminCreateUpdateLayout from "./SuperAdminCreateUpdateLayout"
import { S3LogoHandler } from "./S3LogoHandler"

export default function SuCreateOrganization() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<Omit<Organization, "id">>({
    defaultValues: {
      name: "",
      domain: "",
    },
  })

  const [submitting, setSubmitting] = React.useState(false)

  React.useEffect(() => {
    VerifySuperAdmin()
  }, [])

  const history = useHistory()
  const { mutateAsync: mutateAsyncCreateOrg, isLoading, error } = useCreateOrganization()

  const onSubmit: SubmitHandler<Omit<Organization, "id">> = (data) => {
    NiceModal.show(RootUserEmailPrompt, {
      value: "",
      onSubmit: async (v: { firstName: string; lastName: string; email: string }) => {
        setSubmitting(true)
        const imageUrl = await S3LogoHandler(data?.settings?.logoUrl)
        try {
          await mutateAsyncCreateOrg({ ...(formatForm(data, imageUrl) as Organization), ...v })
          toast.success("Organization created succesfully!")
          history.push("/su-admin/organizations")
          setSubmitting(false)
        } catch (e) {
          toast.error("Failed to create organization!")
          setSubmitting(false)
        }
      },
    })
  }

  return (
    <SuAdminCreateUpdateLayout header="Create New Organization">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ height: "86vh", overflowY: "auto" }}>
          <OrganizationFormFields
            control={control}
            errors={errors}
            organization={undefined}
            reset={reset}
            setValue={setValue}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "16px", paddingRight: "12px" }}>
          <Button
            variation="primary"
            onClick={handleSubmit(onSubmit)}
            isLoading={submitting}
            loadingText="Creating organization..."
          >
            Create Organization
          </Button>
        </div>
      </form>
    </SuAdminCreateUpdateLayout>
  )
}

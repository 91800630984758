import React from "react"
import EcosuiteModule from "@common/module/EcosuiteModule"
import { Error, Loading } from "@common/EcosuiteComponent"
import Schemas from "@common/Schemas"

import RecordService from "../data/record/RecordService"

import CalendarView from "./views/CalendarView"
import ProcessDashboardProjectView from "./views/ProcessDashboardProjectView"
import FunnelView from "./views/funnel/FunnelView"
import ChecklistView from "./views/checklists/ChecklistView"
import ProjectionView from "./views/projections/ProjectionView"

import "@common/module/EcosuiteModule.css"
import "./ProcessModule.css"
import RFIView from "@dashboard/process/views/rfi/RFIView"
import MediaView from "@dashboard/process/views/media/MediaView"
import i18n from "src/i18n"

const { t } = i18n
class ProcessModule extends EcosuiteModule {
  constructor(props) {
    super(props, "process")
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadRecords()
    this.loadProjectMilestones()
  }

  async loadRecords() {
    this.setStateIfMounted({
      records: null,
    })
    let data = await RecordService.listRecords()
    this.setStateIfMounted({
      records: data.records,
    })
  }

  async loadProjectMilestones() {
    const data = await Schemas.getProjectSchema()
    this.setStateIfMounted({ projectSchema: data })
  }

  renderProjectView() {
    switch (this.state.projectView) {
      case "dashboard":
        return <ProcessDashboardProjectView {...this.props} />
      case "tasks": // deprecated view defaults to calendar
      case "calendar":
        return <CalendarView {...this.props} records={this.state.records} selectedProductIds={this.state.selectedProductIds} />
      case "checklist":
        return <ChecklistView code={this.props.project?.code} state={this.props.project?.state} />
      case "media":
        return <MediaView code={this.props.project.code} />
      case "rfi":
        return <RFIView code={this.props.project.code} userId={this.props.userId} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_project_view")} ${this.state.moduleView}` }} />
    }
  }

  render() {
    return <div className="ProcessModule">{super.render()}</div>
  }

  renderModuleView() {
    if (!this.state.projectSchema) {
      return <Loading />
    }

    switch (this.state.moduleView) {
      case "projection":
        return (
          <ProjectionView
            projects={this.props.projects}
            milestonesSchema={this.state.projectSchema?.properties?.milestones?.properties}
            dateRange={this.state.range}
            initDateRange={(start, end) => {
              this.setStateIfMounted({
                // The Projection View only needs this information
                range: { start, end },
                // The Date Selector widget needs this information to render correctly
                start,
                end,
                rangeName: "custom",
              })
            }}
          />
        )
      case "funnel":
        return <FunnelView projects={this.props.projects} projectStatuses={this.state.projectSchema?.properties?.status?.enum} />
      case "target-timeline": // deprecated view defaults to calendar
      case "tasks": // deprecated view defaults to calendar
      case "media":
      case "rfi":
      case "calendar":
        return <CalendarView projects={this.props.projects} records={this.state.records} selectedProductIds={this.state.selectedProductIds} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_process_view")} ${this.state.moduleView}` }} />
    }
  }
}

export default ProcessModule

import React from "react"
import { Button } from "reactstrap"
import Toggle from "react-toggle"
import Moment from "moment"
import { extendMoment } from "moment-range"
import { create, all } from "mathjs"
const math = create(all)
import { Error, Loading, Message } from "@common/EcosuiteComponent"
import { LAYOUT_CONTENT_RIGHT, SideBarSection } from "@common/module/EcosuiteView"
import ConsumptionDatumUtils from "@dashboard/energy/graphs/datums/ConsumptionDatumUtils"
import Emissions from "@dashboard/energy/views/info/Emissions"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import EconodeUtils from "src/econode/EconodeUtils"
import EnergyView from "@econode/energy/views/EconodeEnergyView"
import PreviousPeriodConsumptionComparison from "@econode/energy/views/widgets/PreviousPeriodConsumptionComparison"
import PreviousPeriodConsumption from "@econode/energy/views/widgets/PreviousPeriodConsumption"
import ActualVsPredictedConsumption from "@econode/energy/views/widgets/ActualVsPredictedConsumption"
import PredictedConsumption from "@econode/energy/views/widgets/PredictedConsumption"
import Consumption from "@econode/energy/views/widgets/Consumption"
import { ReferenceLine } from "recharts"
import EconodeConsumptionGraphs from "@econode/energy/views/dashboard/EconodeConsumptionGraphs"
import EconodeConsumptionReadings from "@econode/energy/views/dashboard/EconodeConsumptionReadings"
import { EnergyConsumedPieGraph } from "@econode/energy/views/dashboard/EnergyConsumedPieGraph"
import EconodeRangeConsumption from "@econode/energy/views/dashboard/EconodeRangeConsumption"
import DownloadModal from "@dashboard/energy/DownloadModal"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"

const { t } = i18n
const moment = extendMoment(Moment)

export default class EconodeEnergyDashboardProjectView extends EnergyView {
  constructor(props) {
    super(props)

    this.state = {
      filteredEconodes: [],
      showForecastConsumption: false,
    }
    this.toggleEconode = this.toggleEconode.bind(this)
    this.showReferenceLine = this.showReferenceLine.bind(this)
  }

  getLayout() {
    return LAYOUT_CONTENT_RIGHT
  }

  additionalViewActionButtons() {
    return this.props.restrictions.preventDownload === "yes" ? null : (
      <DownloadModal label={t("buttons.download")} size="sm" className="download-modal" {...this.props} />
    )
  }

  toggleEconode(econodeId) {
    let filteredEconodes = this.state.filteredEconodes
    if (filteredEconodes.indexOf(econodeId) >= 0) {
      filteredEconodes = filteredEconodes.filter((id) => id !== econodeId)
    } else {
      filteredEconodes.push(econodeId)
    }
    this.setStateIfMounted({ filteredEconodes: filteredEconodes })
  }

  renderMainView() {
    if (!this.props.range) return null
    return (
      <div className="Econode">
        <div className="content" style={{ width: "100%" }}>
          <div className="econode-graphs">
            {this.renderInfo()}
            {this.renderEconodeToggles()}
            {this.state.filteredEconodes.length === 0 && this.isContentValid(this.props.readings) ? (
              this.renderEconodeGraphs()
            ) : (
              <Message message={`${t("econode.messages.no_econodes_selected")}`} />
            )}
          </div>
        </div>
      </div>
    )
  }

  getPeakApparentPower(aggregatedTotals) {
    if (!aggregatedTotals) return 0
    return math.divide(Math.max(...Object.values(aggregatedTotals).map((datum) => datum.apparentPowerMax)), 1000)
  }

  renderInfo() {
    const demandPeak =
      this.isContentValid(this.props.datums) && this.props.datums.aggregatedTotals
        ? Object.values(this.props.datums.aggregatedTotals).reduce((peak, datum) => {
            if (datum.consumptionMax > peak) {
              return datum.consumptionMax
            } else {
              return peak
            }
          }, 0)
        : 0

    const peakApparentPower = this.props.datums ? this.getPeakApparentPower(this.props.datums.aggregatedTotals) : 0

    return (
      <div className="econode-info">
        <div className="econode-info-spot">
          <div className="econode-info-spot-label" id="consumption-tab">
            {`${t("labels.consumption")} (kWh):`}{" "}
          </div>
          <div className="econode-info-spot-content">
            {this.props.readings
              ? EnergyUtils.formatNumber(EnergyUtils.convertToKilo(this.props.readings.consumption))
              : "-"}
          </div>
          <UncontrolledTooltip target="consumption-tab">{`${t("econode.labels.consumption")}`}</UncontrolledTooltip>
        </div>
        <div className="econode-info-spot">
          <div className="econode-info-spot-label" id="peak-demand-tab">
            {`${t("econode.dataTitles.Peak Demand")} (kW):`}{" "}
          </div>
          <div className="econode-info-spot-content">
            {demandPeak ? EnergyUtils.formatNumber(EnergyUtils.convertToKilo(demandPeak)) : "-"}
          </div>
          <UncontrolledTooltip target="peak-demand-tab">{`${t("econode.labels.peak_demand")}`}</UncontrolledTooltip>
        </div>
        <div className="econode-info-spot">
          <div className="econode-info-spot-label" id="apparant-power-tab">
            {`${t("econode.dataTitles.Peak Apparent Power")} (kVA):`}{" "}
          </div>
          <div className="econode-info-spot-content">
            {peakApparentPower ? EnergyUtils.formatNumber(peakApparentPower) : "-"}
          </div>
          <UncontrolledTooltip target="apparant-power-tab">{`${t(
            "econode.labels.peak_apparant",
          )}`}</UncontrolledTooltip>
        </div>
      </div>
    )
  }

  renderEconodeToggles() {
    return (
      <>
        <div className="econode-toggles">
          {EconodeUtils.getEconodesForProject(this.props.project).map((econode) => {
            return (
              <Button
                key={econode.code}
                className={this.state.filteredEconodes.indexOf(econode.code) < 0 ? "active" : null}
                onClick={() => this.toggleEconode(econode.code)}
                title={econode.code}
              >
                {econode.name}
              </Button>
            )
          })}
        </div>

        {/* Duplicating this div so that this toggle does not move when econode projects change */}
        <div className="econode-toggles">
          <label>
            <Toggle
              defaultChecked={this.state.showForecastConsumption}
              onChange={() => {
                this.setStateIfMounted((prev) => ({ showForecastConsumption: !prev.showForecastConsumption }))
              }}
            />
            <span className="react-toggle-text">{t("econode.dataTitles.Forecast Consumption")}</span>
          </label>
        </div>
      </>
    )
  }

  getEconodes() {
    return EconodeUtils.getEconodesForProject(this.props.project).filter(
      (econode) => this.state.filteredEconodes.indexOf(econode.code) < 0,
    )
  }

  showReferenceLine(referenceId, value, label) {
    const state = {}
    if (value) {
      state[referenceId] = (
        <ReferenceLine y={value} stroke="white" label={{ position: "top", value: label, fill: "white" }} />
      )
    } else {
      state[referenceId] = null
    }
    this.setStateIfMounted(state)
  }

  renderEconodeGraphs() {
    if (this.isContentValid(this.props.datums)) {
      const econodes = this.getEconodes()
      const graphDatums = ConsumptionDatumUtils.groupDatums(
        econodes,
        EconodeUtils.mapEconodeDatums(this.props.project, this.props.datums),
      )

      return (
        <React.Fragment>
          {this.props.readings.consumption > 0 ? (
            <EconodeConsumptionGraphs
              {...this.props}
              readonly={this.isReadOnly()}
              econodes={econodes}
              graphDatums={graphDatums}
              consumptionReferenceLine={this.state.consumptionReferenceLine}
              demandReferenceLine={this.state.demandReferenceLine}
              powerFactorReferenceLine={this.state.powerFactorReferenceLine}
              apparantPowerReferenceLine={this.state.apparantPowerReferenceLine}
              showForecastConsumption={this.state.showForecastConsumption}
            />
          ) : null}
          {graphDatums ? (
            <EconodeConsumptionReadings
              {...this.props}
              graphDatums={graphDatums}
              consumptionReferenceLine={this.state.consumptionReferenceLine}
              demandReferenceLine={this.state.demandReferenceLine}
              powerFactorReferenceLine={this.state.powerFactorReferenceLine}
              showReferenceLine={this.showReferenceLine}
            />
          ) : null}
        </React.Fragment>
      )
    } else if (this.isContentError(this.props.datums)) {
      return <Error error={this.props.datums.getError()} />
    } else {
      return <Loading />
    }
  }

  renderSiderBar() {
    const thisMonthRange = moment.range(moment().startOf("month"), moment().endOf("day"))

    return (
      <React.Fragment>
        <SideBarSection
          title={t("dates.This Month")}
          classNames="disregard-range"
          range={thisMonthRange}
          content={
            <EconodeRangeConsumption
              project={this.props.project}
              range={thisMonthRange}
              peakApparentPower={this.props.datums ? this.getPeakApparentPower(this.props.datums.aggregatedTotals) : 0}
            />
          }
        />

        {this.props.showConsumption && (
          <>
            <SideBarSection
              title={`${t("econode.dataTitles.Actual This Period")}`}
              range={this.props.actualRange}
              content={<Consumption id="actual-this-period" readings={this.props.readings} />}
            />
            <SideBarSection
              title={`${t("econode.dataTitles.Actual Last Period")}`}
              range={this.props.previousRange}
              content={
                <PreviousPeriodConsumption id="actual-last-period" previousReadings={this.props.previousReadings} />
              }
            />
            <SideBarSection
              title={`${t("econode.dataTitles.Compared to Last Period")}`}
              content={
                <PreviousPeriodConsumptionComparison
                  id="compared-last-period"
                  readings={this.props.readings}
                  previousReadings={this.props.previousReadings}
                />
              }
            />
            {this.props.readings && (
              <UncontrolledTooltip target="actual-this-period">
                {t("econode.labels.actual_this_period")}
              </UncontrolledTooltip>
            )}
            {this.props.previousReadings && (
              <UncontrolledTooltip target="actual-last-period">
                {t("econode.labels.actual_last_period")}
              </UncontrolledTooltip>
            )}
            {this.props.previousReadings && this.props.readings && (
              <UncontrolledTooltip target="compared-last-period">
                {t("econode.labels.compared_last_period")}
              </UncontrolledTooltip>
            )}
            {/* Display forecast consumption widgets only if toggle is enabled */}
            {this.state.showForecastConsumption && (
              <>
                <SideBarSection
                  title={`${t("econode.dataTitles.Forecast This Period")}`}
                  range={this.props.actualRange}
                  content={
                    <PredictedConsumption
                      readings={this.props.readings}
                      predictedConsumption={this.props.predictedConsumption}
                    />
                  }
                />
                <SideBarSection
                  title={`${t("econode.dataTitles.Compared to Forecast")}`}
                  content={
                    <ActualVsPredictedConsumption
                      readings={this.props.readings}
                      predictedConsumption={this.props.predictedConsumption}
                    />
                  }
                />
              </>
            )}

            {/* 
              EP-2803
              https://ecogyenergy.atlassian.net/jira/software/c/projects/EP/boards/2?modal=detail&selectedIssue=EP-2803&assignee=6254fe3ffdb60b006927952d  
            */}
            {/* <SideBarSection title="Trend This Period" content={<ActualPredictedConsumptionDifference readings={this.props.readings} predictedConsumption={this.props.predictedConsumption} />} /> */}

            <SideBarSection
              title={`${t("econode.dataTitles.Energy Consumed")}`}
              content={<EnergyConsumedPieGraph econodes={this.getEconodes()} readings={this.props.readings} />}
            />
            <Emissions readings={this.props.readings} sourceType="consumption" />
          </>
        )}
      </React.Fragment>
    )
  }
}

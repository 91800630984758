import React from "react"
import { Alert, FormGroup, Input, Label } from "reactstrap"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import UserAdminService from "./UserAdminService"
import Logger from "@common/Logger"
import Icon from "@common/display/Icon"
import { SECURE_GROUPS } from "src/App"
import { isEqual } from "lodash"
import i18n from "src/i18n"

const { t } = i18n
class UserGroups extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {}
    this.toggleGroup = this.toggleGroup.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadUserGroups()
    this.formatUserGroups()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.user !== prevProps.user || this.props.forceUpdate !== prevProps.forceUpdate) {
      this.loadUserGroups()
      this.formatUserGroups()
    }
  }

  loadUserGroups() {
    this.setState({ error: undefined, userGroups: undefined, message: false, success: false })
    UserAdminService.getUserGroups(this.props.user)
      .then((response) => {
        this.setConflicting(response.groups)
        this.setStateIfMounted({ userGroups: response.groups })
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false })
      })
  }

  formatUserGroups() {
    UserAdminService.getUserGroupSchema().then((schema) => {
      const categoryOrder = schema.groups.categoryOrder
      const groupIDs = schema.groups.items.enum
      const groupDescriptions = schema.groups.items.enumNames.filter(Boolean)

      // Separate the CognitoGroups into their individual categories by converting string to object notation.
      // ex: "Product: Generation" => { Product: [{ description: "Generation", id: "generation" }, ... ] }
      let groups = {}
      groupDescriptions?.map((description, index) => {
        const [groupType, groupDescription] = description.split(": ")
        if (groupDescription) {
          groups[groupType] = groups[groupType] ?? []
          groups[groupType].push({ description: groupDescription, id: groupIDs[index] })
        } else {
          groups.admin = groups.admin ?? []
          groups.admin.push({ description: groupType, id: groupIDs[index] })
        }
      })

      // Create an array of separated CognitoGroups that is sorted by categoryOrder
      groups = Object.entries(groups).sort(([a], [b]) => {
        return categoryOrder.indexOf(a.toLowerCase()) - categoryOrder.indexOf(b.toLowerCase())
      })
      this.setState({ formattedUserGroups: groups })
    })
  }

  toggleGroup(event) {
    this.setState({ error: undefined })

    let groupId = event.target.value
    if (event.target.checked) {
      let userGroups = this.state.userGroups
      userGroups.push(
        this.props.groups.find((userGroup) => {
          return userGroup.id === groupId
        }),
      )
      this.setState({
        message: `${t("settings.messages.adding_user_to_group")}: ${groupId}`,
        success: undefined,
        userGroups: userGroups,
      })

      UserAdminService.addUserToGroup(this.props.user, groupId)
        .then(() => {
          this.setConflicting(userGroups)
          this.setState({
            success: `${t("settings.messages.user_added_to_group")}: ${groupId}`,
            message: undefined,
            // userGroups: userGroups
          })
        })
        .catch((err) => {
          Logger.error(err)
          this.setState({ error: err, success: false })
          this.loadUserGroups()
        })
    } else {
      let userGroups = this.state.userGroups.filter((userGroup) => {
        return userGroup.id !== groupId
      })
      this.setState({
        message: `${t("settings.messages.removing_user_to_group")}: ${groupId}`,
        success: undefined,
        userGroups: userGroups,
      })

      UserAdminService.removeUserFromGroup(this.props.user, groupId)
        .then(() => {
          this.setConflicting(userGroups)
          this.setState({
            success: `${t("settings.messages.user_removed_from_group")}: ${groupId}`,
            message: undefined,
            userGroups: userGroups,
          })
        })
        .catch((err) => {
          Logger.error(err)
          this.setState({ error: err, success: false })
          this.loadUserGroups()
        })
    }

    // event.preventDefault();
  }

  isGroupSelected(group) {
    let userGroup = this.state.userGroups.find((userGroup) => {
      return userGroup.id === group.id
    })
    return userGroup !== undefined
  }

  /**
   * Checks if the userGroups for the selected User are the same as their user type's defaults
   */
  setConflicting(responseGroups) {
    // Ensure that this prop function exists before trying to execute it
    if (typeof this.props.setConflicting !== "function") {
      return
    }

    if (!isEqual(responseGroups.map((group) => group.id).sort(), [...this.props.defaultGroups].sort())) {
      this.props.setConflicting(true)
    } else {
      this.props.setConflicting(false)
    }
  }

  renderUserGroupAlerts() {
    const error = this.getError()

    if (this.state.message) {
      return <Alert color="info">{this.state.message}</Alert>
    } else if (this.state.success) {
      return <Alert color="success">{this.state.success}</Alert>
    } else if (error) {
      return <Alert color="danger">{error}</Alert>
    } else if (this.props.typeofNewUser) {
      return (
        <Alert color="info" className="info">
          <Icon icon="info" />
          {this.props.typeofNewUser
            ? `${t("settings.messages.new_user_default_permissions")}`
            : `${t("settings.messages.user_default_permissions")}`}
        </Alert>
      )
    }
  }

  renderGroups(category, groups) {
    return (
      <div key={category}>
        <h4 style={{ textTransform: "capitalize" }}>{category}</h4>
        <table className="user-groups">
          <tbody>
            {groups.map((group) => {
              return (
                <tr key={group.id}>
                  <td className="user-group-name">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          value={group.id}
                          onChange={this.toggleGroup}
                          checked={this.isGroupSelected(group)}
                        />{" "}
                        {group.id}
                      </Label>
                    </FormGroup>
                  </td>
                  <td>{`${group.description}${
                    SECURE_GROUPS.includes(group.id) ? `${t("settings.messages.will_require_MFA")}` : ""
                  }`}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  renderContent() {
    if (this.props.groups && this.state.userGroups) {
      return (
        <div className="ecogy-form admin-tab-content-area">
          {!this.props.typeofNewUser && (
            <Alert color="info" className="info">
              <Icon icon="info" />
              {t("settings.messages.user_permissions_changes_not_reflected")}
            </Alert>
          )}
          {this.renderUserGroupAlerts()}
          {this.state.formattedUserGroups?.map(([category, groups]) => this.renderGroups(category, groups))}
        </div>
      )
    } else {
      return <Loading />
    }
  }

  getError() {
    if (this.state && this.state.error) {
      return this.state.error.message ? this.state.error.message : this.state.error
    }
    return null
  }
}

export default UserGroups

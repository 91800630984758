import { Auth, Cache } from "aws-amplify"
import moment from "moment"
import EnergyService from "@dashboard/energy/EnergyService"
import EventService from "@dashboard/event/EventService"
import Logger from "./Logger"
import { getErrorMessage } from "./SharedComponentUtils"
import { DateTime } from "luxon"
import { AWSError } from "aws-sdk"

export interface IRange {
  start: moment.Moment | DateTime
  end: moment.Moment | DateTime
}

/**
 * Fetch with retries options.
 */
export interface FetchRetryOptions extends RequestInit {
  maxRetries?: number
  retryIfResponse?: (response: Response) => boolean
  retryIfError?: (error: unknown) => boolean
  retryStatuses?: number[]
  delayFactorMilliseconds?: number
}

/**
 * Fetch with retries.
 * @param url - The URL to fetch.
 * @param options - The fetch options.
 * @param retryCount - The current retry count (to be used recursively).
 */
export const fetchRecursiveRetry = async (url: string, options: FetchRetryOptions, retryCount = 0): Promise<Response> => {
  const { maxRetries = 3, delayFactorMilliseconds = 200, retryStatuses = [504], ...remainingOptions } = options

  try {
    const response = await fetch(url, remainingOptions)
    if (response.ok) {
      return response
    } else {
      if (retryCount < maxRetries) {
        // Retry if:
        // - the status code is in the retryStatuses array
        // or:
        // - there is a retryIfResponse function and it returns true
        if (retryStatuses.includes(response.status) || (options.retryIfResponse && options.retryIfResponse(response))) {
          await new Promise((resolve) => setTimeout(resolve, delayFactorMilliseconds))
          return fetchRecursiveRetry(url, options, retryCount + 1)
        }
      }

      return response
    }
  } catch (error) {
    // if the retryCount has not been exceeded, call again
    if (retryCount < maxRetries) {
      // If there isn't a retryIf function or the retryIf function returns true,
      // then we retry.
      if (options.retryIfError && options.retryIfError(error)) {
        await new Promise((resolve) => setTimeout(resolve, delayFactorMilliseconds))
        return fetchRecursiveRetry(url, options, retryCount + 1)
      }
    }

    throw error
  }
}

const API = {
  pendingGets: {} as Record<string, Promise<any>>,

  clearCache() {
    Logger.debug("Clearing cache")
    this.pendingGets = {} // We also clear any pending requests
    Cache.clear()

    // We give the more immediate data a chance to load before warming the local cache
    setTimeout(this.warmCache, 1000)
  },

  warmCache() {
    Logger.debug("Warm cache")
    Auth.currentSession().then((session) => {
      // @ts-ignore
      const groups = session.idToken.payload["cognito:groups"]

      if (groups.includes("energy")) {
        EnergyService.warmCache()
      }
      if (groups.includes("events")) {
        EventService.warmCache()
      }
    })
  },

  getFromCache<T>(url: string, expires?: number): Promise<T> {
    const cachedValue: T = Cache.getItem(url)
    if (cachedValue) {
      return Promise.resolve(cachedValue)
    } else {
      return this.cacheGet(url, expires)
    }
  },

  cacheGet<T>(url: string, expires?: number): Promise<T> {
    const promise: Promise<T> | undefined = this.pendingGets[url]
    if (promise) {
      // If the same request is already pending then we just wait for it
      return promise
    }

    const request: Promise<T> = this.get<T>(url).then((result) => {
      if (expires) {
        Cache.setItem(url, result, { expires: expires })
      } else {
        Cache.setItem(url, result)
      }
      delete this.pendingGets[url] // We delete the pending request as it's now complete
      return result
    })

    this.pendingGets[url] = request

    return request
  },

  async get<T>(url: string, options?: FetchRetryOptions): Promise<T> {
    // First we check for a cached value as it may have been explicitly cached
    const cachedValue = Cache.getItem(url)
    if (cachedValue) {
      return Promise.resolve(cachedValue) as Promise<T>
    }

    // If there isn't one (most cases) get the value WITHOUT caching it
    return Auth.currentSession()
      .then((session) => {
        return fetchRecursiveRetry(process.env.REACT_APP_ECOGY_API_URL + url, {
          headers: {
            // @ts-ignore
            Authorization: session.idToken.jwtToken
          },
          ...options
        })
          .then((response) => {
            return this.handleResponse(response) as Promise<T>
          })
          .catch((err) => {
            return this.handleError(err)
          })
      })
      .catch((err) => {
        return this.handleError(err)
      })
  },

  async post<T, K>(url: string, body?: K): Promise<T> {
    return Auth.currentSession()
      .then((session) => {
        return fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
          method: "post",
          headers: {
            // @ts-ignore
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        })
          .then((response) => {
            return this.handleResponse(response)
          })
          .catch((err) => {
            return this.handleError(err)
          })
      })
      .catch((err) => {
        return this.handleError(err)
      })
  },

  async postFormData(url: string, formData: FormData) {
    return Auth.currentSession()
      .then((session) => {
        return fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
          headers: {
            // @ts-ignore
            Authorization: session.idToken.jwtToken
          },
          method: "post",
          body: formData
        })
          .then((response) => {
            return this.handleResponse(response)
          })
          .catch((err) => {
            return this.handleError(err)
          })
      })
      .catch((err) => {
        return this.handleError(err)
      })
  },

  async put<T, K>(url: string, body?: K): Promise<T> {
    return Auth.currentSession()
      .then((session) => {
        return fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
          headers: {
            // @ts-ignore
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json"
          },
          method: "put",
          body: JSON.stringify(body, (key, value) => (typeof value === "undefined" ? null : value))
        })
          .then((response) => {
            return this.handleResponse(response)
          })
          .catch((err) => {
            return this.handleError(err)
          })
      })
      .catch((err) => {
        return this.handleError(err)
      })
  },

  async delete<T, K>(url: string, body?: K): Promise<T> {
    return Auth.currentSession()
      .then((session) => {
        return fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
          headers: {
            // @ts-ignore
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json"
          },
          method: "delete",
          body: JSON.stringify(body)
        })
          .then((response) => {
            return this.handleResponse(response)
          })
          .catch((err) => {
            return this.handleError(err)
          })
      })
      .catch((err) => {
        return this.handleError(err)
      })
  },

  handleResponse(response: Response) {
    if (response.ok) {
      if (response.headers.get("Content-Type") === "application/json") {
        return response.json()
      } else {
        return response
      }
    } else if (response.status === 404) {
      return null
    } else {
      return response.json().then((error) => {
        return this.handleError(error)
      })
    }
  },

  handleError(err: string | { code: string } | AWSError) {
    switch (typeof err) {
      case "string":
        if (err === "No current user") {
          Logger.info("Session expired")
        }

        // In the case of a string, return a string as the error message.
        throw { message: err }
      case "object":
        if (err.code && err.code === "NotAuthorizedException") {
          Logger.info("Session expired")
        }

        // In the case of an object, return the error message and code.
        throw { message: getErrorMessage(err), ...err }
    }
  },

  getDefaultCacheTimeout() {
    return this.getCacheTimeout(5)
  },

  getCacheTimeout(minutes: number) {
    return moment().add(minutes, "minutes").toDate()
  },

  addRangeToUrl(url: string, range: IRange | undefined | null) {
    let start: string
    let end: string
    if (!range) return

    if (range.start instanceof moment) {
      start = moment(range.start).format("YYYY-MM-DDTHH:mm")
    } else {
      // instanceof DateTime
      start = DateTime.fromISO(range.start.toString()).toFormat("yyyy-MM-dd'T'HH:mm")
    }
    if (range.end instanceof moment) {
      end = moment(range.end).format("YYYY-MM-DDTHH:mm")
    } else {
      // instanceof DateTime
      end = DateTime.fromISO(range.end.toString()).toFormat("yyyy-MM-dd'T'HH:mm")
    }
    return (url += (url.indexOf("?") > 0 ? "&" : "?") + "start=" + start + "&end=" + end)
  },

  addParamToUrl(url: string, key: string, value: string) {
    if (url.indexOf("?") < 0) {
      url += `?${key}=${value}`
    } else {
      url += `&${key}=${value}`
    }
    return url
  }
}

export default API


import React from "react"
import { TabContent, TabPane, NavLink } from "reactstrap"

import EcosuiteComponent from "@common/EcosuiteComponent"
import UserAudit from "./user-audit/UserAudit"
import UserTypes from "./user-type/UserTypes"
import Users from "./user/Users"
import i18n from "src/i18n"

const { t } = i18n
export default class UserAdmin extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.selectTab = this.selectTab.bind(this)

    this.state = {
      activeTab: window.location.hash.startsWith("#user-types") ? "user-types" : "users",
    }
  }

  selectTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  renderContent() {
    return (
      <div className="Admin">
        <ul className="nav nav-tabs app-mode">
          <li className={`nav-item${this.state.activeTab === "users" ? " selected" : ""}`}>
            <NavLink
              className="active nav-link"
              onClick={() => {
                this.selectTab("users")
              }}
            >
              {t("settings.labels.users")}
            </NavLink>
          </li>
          <li className={`nav-item${this.state.activeTab === "user-types" ? " selected" : ""}`}>
            <NavLink
              className="active nav-link"
              onClick={() => {
                this.selectTab("user-types")
              }}
            >
              {t("settings.labels.userTypes")}
            </NavLink>
          </li>
          <li className={`nav-item${this.state.activeTab === "user-audit" ? " selected" : ""}`}>
            <NavLink
              className="active nav-link"
              onClick={() => {
                this.selectTab("user-audit")
              }}
            >
              {t("settings.labels.audit")}
            </NavLink>
          </li>
        </ul>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="users">
            <Users {...this.props} />
          </TabPane>
          <TabPane tabId="user-types">
            <UserTypes {...this.props} />
          </TabPane>
          <TabPane tabId="user-audit">{this.state.activeTab == "user-audit" && <UserAudit {...this.props} />}</TabPane>
        </TabContent>
      </div>
    )
  }
}

import API from "@common/API"

var ConnectivityService = {
  getConnectivity(projects, range) {
    let url = API.addRangeToUrl("/connectivity/data?projectIds=" + projects.map((project) => project.code).join(","), range)
    return API.getFromCache(url, API.getDefaultCacheTimeout())
  },

  getDevices() {
    let url = "/connectivity/devices"
    return API.getFromCache(url, API.getDefaultCacheTimeout())
  },
}

export default ConnectivityService

import React from "react"
import { ResponsiveContainer, XAxis, YAxis, Tooltip, LineChart, Line, Legend } from "recharts"
import moment from "moment"

import prettyBytes from "src/pretty-bytes"

import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

class ProjectConnectivityGraph extends EcosuiteComponent {
  getData() {
    let data = []
    if (this.props.connectivity) {
      this.props.projects.forEach((project) => {
        let projectConnectivity = this.props.connectivity.projects[project.code]
        Object.keys(projectConnectivity.sites).forEach((siteCode) => {
          let siteConnectivity = projectConnectivity.sites[siteCode]
          siteConnectivity.data.forEach((entry, idx) => {
            if (!data[idx]) {
              data[idx] = {
                date: entry.date,
              }
            }
            if (data[idx][project.code]) {
              data[idx][project.code] += entry.bytes
            } else {
              data[idx][project.code] = entry.bytes
            }
          })
        })
      })
    }
    return data
  }

  getLines() {
    let lines = []
    let idx = 0
    this.props.projects.forEach((project) => {
      let code = project.code
      lines.push(<Line key={code} type="linear" dataKey={code} stroke={GRAPH_COLORS[idx++ % GRAPH_COLORS.length]} dot={true} />)
    })
    return lines
  }

  renderContent() {
    if (this.props.connectivity) {
      let data = this.getData()
      return (
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={data} margin={{ top: 0, right: 30, bottom: 10, left: 50 }}>
            <XAxis dataKey="date" tickFormatter={(time) => moment(time).format(this.props.connectivity.aggregation === "hour" ? "HH:mm" : "MMM-DD")} />
            <YAxis tickFormatter={prettyBytes} />

            <Tooltip
              wrapperStyle={{ color: "#000", pointerEvents: "auto" }}
              // eslint-disable-next-line no-unused-vars
              formatter={(value, name, props) => {
                return prettyBytes(value)
              }}
              labelFormatter={(label) => {
                return moment(label).format("lll")
              }}
            />
            {this.getLines()}

            <Legend />
          </LineChart>
        </ResponsiveContainer>
      )
    } else {
      return <Loading />
    }
  }
}

export default ProjectConnectivityGraph

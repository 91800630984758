import { useMutation, useQuery, useQueryClient } from "react-query"
import { get, post } from "../setup"
import { ONBOARDING_USER_TYPE } from "src/PublicView/Onboarding/PrivateRoute"

export interface DocumentItem {
  fileKey: string
  name: string
  updated: string
}

interface UserOnboardingDocuments {
  documents: DocumentItem[]
}

export interface User {
  created: string
  displayName: string
  id: string
  status: string
  updated: string
  attributes: {
    userType: string
  }
}

export interface Project {
  code: string
  name: string
}

export interface OnboardingToDistillary {
  fileName: string
  fileKey: string
  projectId: string
  userId: string
}

export interface MarkAsDone {
  fileKeys: string[]
  userId: string
}

export interface MetaData {
  id: string
  markedDone?: string[]
  sentToDistillary?: string[]
}

interface OnboardingToDistillaryResponse {
  success: boolean
  sourceKey: string
  destinationKey: string
  eTag: string
}

interface MarkedDoneResponse {
  success: boolean
}

export const getOnboardingDocuments = (userId?: string) =>
  get<UserOnboardingDocuments>(`/onboarding-documents${userId ? `?userId=${userId}` : ""}`)
export const getUsers = () => get<{ users: User[] }>("/users")
export const getAdminOnboardingData = (userId: string) => get<MetaData>(`/onboarding-admin?userId=${userId}`)

export const getUserOnboardingData = () => get<MetaData>(`/onboarding-user`)

export const getProjects = () => get<{ projects: Project[] }>("/projects")
export const onboardingToDistillary = (body: OnboardingToDistillary) =>
  post<OnboardingToDistillary, OnboardingToDistillaryResponse>("/onboarding-distillary", body)
export const markAsDone = (body: MarkAsDone) => post<MarkAsDone, MarkedDoneResponse>("/onboarding-mark-done", body)

export function useOnboardingToDistillary() {
  const queryClient = useQueryClient()
  return useMutation(onboardingToDistillary, {
    onSuccess: () => {
      queryClient.invalidateQueries("onboarding-data-admin")
    },
    onError: (e: Error) => {
      console.error(e)
    },
  })
}

export function useMarkAsDone() {
  const queryClient = useQueryClient()
  return useMutation(markAsDone, {
    onSuccess: () => {
      queryClient.invalidateQueries("onboarding-data-admin")
    },
    onError: (e: Error) => {
      console.error(e)
    },
  })
}

export function useGetAdminOnboardingData(userId: string) {
  return useQuery({
    queryKey: ["onboarding-data-admin"],
    queryFn: async () => {
      const response = await getAdminOnboardingData(userId)
      return response
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })
}

export function useGetUserOnboardingData() {
  return useQuery({
    queryKey: ["onboarding-data-user"],
    queryFn: async () => {
      const response = await getUserOnboardingData()
      return response
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })
}

export function useGetOnboardingDocuments(userId?: string) {
  return useQuery({
    queryKey: ["onboarding-documents"],
    queryFn: async () => {
      const response = await getOnboardingDocuments(userId)
      return response
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })
}

export function useGetOnboardingUsers() {
  return useQuery({
    queryKey: ["onboarding-users"],
    queryFn: async () => {
      const response = await getUsers()
      return response.users.filter((user) => user.attributes.userType === ONBOARDING_USER_TYPE)
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })
}

export function useGetProjects() {
  return useQuery({
    queryKey: ["projects"],
    queryFn: async () => {
      const response = await getProjects()
      return response
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

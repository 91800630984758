import React from "react"
import { PieChart, Pie, Tooltip, Cell, Legend } from "recharts"

import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

class OutageGraph extends EcosuiteComponent {
  render() {
    if (this.props.connectivity) {
      let units = this.props.connectivity.aggregation === "hour" ? "Hours" : "Days"

      let out = 0,
        on = 0
      this.props.projects.forEach((project) => {
        let projectConnectivity = this.props.connectivity.projects[project.code]
        Object.values(projectConnectivity.sites).forEach((siteConnectivity) => {
          siteConnectivity.data.forEach((entry) => {
            if (entry.bytes > 0) {
              on++
            } else {
              out++
            }
          })
        })
      })
      let data = [
        { name: "Connected " + units, connections: on },
        { name: "Outage " + units, connections: out },
      ]

      return (
        <div className="sidebar-graph">
          <PieChart width={300} height={200} onMouseEnter={this.onPieEnter}>
            <Pie data={data} dataKey="connections" nameKey="name" labelLine={false} outerRadius={80} fill="#8884d8">
              {data.map((entry, index) => (
                <Cell fill={GRAPH_COLORS[index + (1 % GRAPH_COLORS.length)]} key={index} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

export default OutageGraph

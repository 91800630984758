import { Button } from "@aws-amplify/ui-react"
import * as React from "react"
import { useHistory } from "react-router-dom"
import { SignOut } from "./SuAdmin"

interface SuAdminCreateUpdateLayoutProps {
  header: string
  children: JSX.Element
  onBack?: () => void
}

export default function SuAdminCreateUpdateLayout(props: SuAdminCreateUpdateLayoutProps) {
  const { header, children, onBack } = props
  const history = useHistory()
  return (
    <div style={{ padding: "8px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variation="link"
          color="overlay.90"
          style={{ backgroundColor: "white", border: "1px solid white" }}
          onClick={() => {
            onBack && onBack()
            history.push("/su-admin/organizations")
          }}
        >
          Back
        </Button>
        <Button style={{ backgroundColor: "grey" }} onClick={() => SignOut()}>
          Logout
        </Button>
      </div>
      <h2 style={{ textAlign: "center" }}>{header}</h2>
      {children}
    </div>
  )
}

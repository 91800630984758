import * as React from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { DataType, SortDirection, SortingMode, Table, useTable, useTableInstance } from "ka-table"
import { ICellTextProps } from "ka-table/props"
import { kaPropsUtils } from "ka-table/utils"
import { CheckCircle2Icon, DownloadIcon } from "lucide-react"
import { EyeOpenIcon } from "@radix-ui/react-icons"
import {
  DocumentItem,
  Project,
  useGetAdminOnboardingData,
  useGetOnboardingDocuments,
  useGetProjects,
  useMarkAsDone,
  useOnboardingToDistillary,
} from "src/services/onboarding"
import { Spinner } from "src/components/ui/spinner"
import { downloadFile, PreviewDialog, PreviewFile, useFilePreview, viewFile } from "../Components/Preview"
import { Button } from "src/components/ui/button"
import { Checkbox } from "src/components/ui/checkbox"
import { H4 } from "src/components/ui/typography"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "src/components/ui/dialog"
import { Label } from "src/components/ui/label"
import { Select } from "src/components/ui/rcselect"
import { Textarea } from "src/components/ui/textarea"
import LoadingButton from "src/components/ui/loading-button"
import { Badge } from "src/components/ui/badge"

type FileMetaData = {
  fileKey: string
  name: string
  updated: string
}

type ActionButtonsProps = {
  rowData: DocumentItem
}

type SelectionCellProps = ICellTextProps & {
  rowKeyValue: string
  isSelectedRow: boolean
  selectedRows: string[]
}

type DistilleryDialogProps = {
  isOpen: boolean
  onClose: () => void
  projects: Project[]
  file: FileMetaData
  userId: string
}

const handleFileDownload = async (file: PreviewFile): Promise<void> => {
  try {
    const url = await viewFile(file.fileKey!)
    await downloadFile(url, file.fileName!)
    toast.success("Successfully downloaded file! Check your downloads folder.")
  } catch (error) {
    toast.error("Download failed!")
  }
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ rowData }) => {
  const [loadingDownload, setLoadingDownload] = React.useState<boolean>(false)
  const { previewFile, setPreviewFile, pdfLoadError, handlePreview, isLoading: loadingPreview } = useFilePreview()

  const handleDownload = async (file: PreviewFile) => {
    setLoadingDownload(true)
    await handleFileDownload(file)
    setLoadingDownload(false)
  }

  return (
    <>
      <PreviewDialog
        previewFile={previewFile}
        setPreviewFile={setPreviewFile}
        pdfLoadError={pdfLoadError}
        isLoading={loadingPreview}
      />
      <div className="tw-flex">
        <Button
          size="sm"
          variant="outline"
          className="tw-p-2"
          disabled={loadingPreview}
          onClick={() => handlePreview({ fileName: rowData.name, fileKey: rowData.fileKey })}
        >
          {loadingPreview ? <Spinner className="tw-h-4 tw-w-4" /> : <EyeOpenIcon className="tw-h-4 tw-w-4" />}
        </Button>
        <Button
          size="sm"
          variant="outline"
          className="tw-p-2 tw-ml-2"
          disabled={loadingDownload}
          onClick={() => handleDownload({ fileName: rowData.name, fileKey: rowData.fileKey })}
        >
          {loadingDownload ? <Spinner className="tw-h-4 tw-w-4" /> : <DownloadIcon className="tw-h-4 tw-w-4" />}
        </Button>
      </div>
    </>
  )
}

const DistilleryDialog: React.FC<DistilleryDialogProps> = ({ isOpen, onClose, projects, file, userId }) => {
  const [selectedProject, setSelectedProject] = React.useState<{ value: string; label: string } | null>(null)
  const [description, setDescription] = React.useState<string>("")

  const { mutateAsync, isLoading } = useOnboardingToDistillary()

  const handleSubmit = async () => {
    if (!selectedProject) return

    try {
      await mutateAsync({ projectId: selectedProject.value, fileKey: file.fileKey, fileName: file.name, userId })
      toast.success("Successfully added to distillery!")
      onClose()
    } catch (e) {
      toast.error("Something went wrong!")
    }
  }

  const projectOptions = projects.map((project) => ({
    value: project.code,
    label: project.name,
  }))

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className="tw-p-2" style={{ wordBreak: "break-all" }}>
          <DialogTitle>Add {file?.name} to Distillery</DialogTitle>
        </DialogHeader>
        <div className="tw-space-y-4 tw-p-2">
          <div>
            <Label htmlFor="project">Project</Label>
            <Select
              options={projectOptions}
              value={selectedProject}
              onChange={(v) => setSelectedProject(v as { label: string; value: string })}
              placeholder="Select a project"
            />
          </div>
          <div>
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter a description"
            />
          </div>
          <LoadingButton onClick={handleSubmit} loading={isLoading} disabled={!selectedProject}>
            Upload To Distillery
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const SelectionCell: React.FC<SelectionCellProps> = ({ rowKeyValue, isSelectedRow }) => {
  const table = useTableInstance()

  const handleCheckedChange = (checked: boolean) => {
    checked ? table.selectRow(rowKeyValue) : table.deselectRow(rowKeyValue)
  }

  return <Checkbox checked={isSelectedRow} onCheckedChange={handleCheckedChange} />
}

const SelectionHeader: React.FC = () => {
  const table = useTableInstance()
  const areAllRowsSelected = kaPropsUtils.areAllFilteredRowsSelected(table.props)

  const handleCheckedChange = (checked: boolean) => {
    checked ? table.selectAllFilteredRows() : table.deselectAllFilteredRows()
  }

  return <Checkbox checked={areAllRowsSelected} onCheckedChange={handleCheckedChange} />
}

const OnboardingUserDetails: React.FC = () => {
  const { userId } = useParams<{ userId: string }>()
  const { data, isLoading, error, isFetching } = useGetOnboardingDocuments(userId!)
  const { data: metaData, isFetching: fetchingMetaData } = useGetAdminOnboardingData(userId!)
  const { data: projectsData } = useGetProjects()
  const { mutateAsync: markAsDoneAsync, isLoading: markingDone } = useMarkAsDone()

  const [selectedRows, setSelectedRows] = React.useState<string[]>([])
  const [selectedDocument, setSelectedDocument] = React.useState<FileMetaData | null>(null)

  const table = useTable({
    onDispatch: (action, props) => {
      if (
        action.type === "SelectRow" ||
        action.type === "DeselectRow" ||
        action.type === "SelectAllFilteredRows" ||
        action.type === "DeselectAllFilteredRows"
      ) {
        setSelectedRows(props.selectedRows || [])
      }
    },
  })

  React.useEffect(() => {
    if (error) {
      toast.error("Failed to fetch onboarding documents")
    }
  }, [error])

  if (isLoading || isFetching) {
    return (
      <div className="public-view tw-bg-background tw-text-foreground tw-h-screen tw-flex tw-items-center tw-justify-center dark">
        <Spinner size="large">Loading...</Spinner>
      </div>
    )
  }

  const tableData: FileMetaData[] =
    data?.documents.map((doc) => ({
      fileKey: doc.fileKey,
      name: doc.name,
      updated: new Date(doc.updated).toLocaleString(),
    })) || []

  const handleDistilleryClick = (document: FileMetaData) => setSelectedDocument(document)

  const markedDone = metaData?.markedDone || []
  const sentToDistillary = metaData?.sentToDistillary || []

  const handleMarkAsCompleted = async () => {
    const toSend = selectedRows.filter((r) => !markedDone.includes(r))
    if (!toSend.length) {
      toast.warn("Nothing to mark!")
      return
    }
    try {
      await markAsDoneAsync({ fileKeys: toSend, userId: userId! })
      table.deselectAllFilteredRows()
      toast.success("Successfully marked completed!")
    } catch (e) {
      toast.error("Something went wrong")
    }
  }

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-background dark">
      <H4 className="tw-p-4">Onboarding documents</H4>
      <div className="tw-flex tw-justify-end tw-px-4">
        <LoadingButton onClick={handleMarkAsCompleted} disabled={!selectedRows.length} loading={markingDone}>
          Mark as completed
        </LoadingButton>
      </div>
      <div className="twx-ka-table tw-pt-4">
        <Table
          table={table}
          controlledPropsKeys={["loading"]}
          loading={{
            enabled: fetchingMetaData,
            text: "Fetching...",
          }}
          columns={[
            {
              key: "selection-cell",
              isFilterable: false,
              width: 80,
            },
            { key: "action", title: "Actions", dataType: DataType.String, width: 160, isSortable: false },
            { key: "name", title: "Document Name", dataType: DataType.String },
            {
              key: "updated",
              title: "Last Updated",
              dataType: DataType.String,
              width: 280,
              sortDirection: SortDirection.Ascend,
            },
            {
              key: "distill",
              title: "Other Actions",
              width: 280,
              isSortable: false,
            },
          ]}
          virtualScrolling={{ enabled: true }}
          noData={{ text: "No documents found! Please upload documents from upload tab!" }}
          data={tableData}
          rowKeyField="fileKey"
          sortingMode={SortingMode.Single}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case "action":
                    return <ActionButtons rowData={props.rowData} />
                  case "selection-cell":
                    return markedDone.includes(props.rowData.fileKey) ? (
                      <CheckCircle2Icon size={16} color="lightgreen" />
                    ) : (
                      <SelectionCell {...props} />
                    )
                  case "distill":
                    return sentToDistillary.includes(props.rowData.fileKey) ? (
                      <Badge variant="destructive">Added to distillery!</Badge>
                    ) : (
                      <Button onClick={() => handleDistilleryClick(props.rowData)}>Add to distillery</Button>
                    )
                  default:
                    return props.value
                }
              },
            },
            tableWrapper: {
              elementAttributes: () => ({ style: { maxHeight: "calc(100vh - 200px)" } }),
            },
            headCell: {
              content: (props) => (props.column.key === "selection-cell" ? <SelectionHeader /> : null),
            },
          }}
        />
      </div>
      {selectedDocument && (
        <DistilleryDialog
          isOpen={Boolean(selectedDocument)}
          onClose={() => setSelectedDocument(null)}
          projects={projectsData?.projects || []}
          file={selectedDocument}
          userId={userId!}
        />
      )}
    </div>
  )
}

export default OnboardingUserDetails

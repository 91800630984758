import React from "react"
import { Typeahead } from "react-bootstrap-typeahead"

export function addAutoComplete(uiSchema, autoCompletes, readonly, propertyName) {
  if (!uiSchema[propertyName]) {
    uiSchema[propertyName] = {}
  }
  uiSchema[propertyName]["ui:widget"] = (props) => {
    return (
      <Typeahead
        id={`${propertyName}AutoComplete`}
        labelKey={propertyName}
        onChange={(selected) => {
          props.onChange(selected && selected.length ? (selected[0].customOption ? selected[0][propertyName] : selected[0]) : null)
        }}
        options={autoCompletes ? Object.keys(autoCompletes[propertyName]).sort() : []}
        selected={props.value ? [props.value] : []}
        allowNew={true}
        disabled={readonly}
      />
    )
  }
}

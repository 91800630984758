import React from "react"

import moment from "moment"

import prettyBytes from "src/pretty-bytes"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import Settings from "@common/Settings"

import { SideBarSection, LAYOUT_CONTENT_BOTTOM_RIGHT } from "@common/module/EcosuiteView"
import EventLineGraph from "@dashboard/event/line-graph/EventLineGraph"

import ConnectivityMapView from "./ConnectivityMapView"
import ProjectsTable from "../ProjectsTable"
import ConnectivityTotalGraph from "../graphs/ConnectivityTotalGraph"
import ProjectConnectivityGraph from "../graphs/ProjectConnectivityGraph"
import ProviderBarGraph from "../graphs/ProviderBarGraph"
import OutageGraph from "../graphs/OutageGraph"
import GraphSelector from "@dashboard/GraphSelector"
import Aggregations from "@common/Aggregations"
import i18n from "src/i18n"

const { t } = i18n
export default class ConnectivityOverView extends ConnectivityMapView {
  constructor(props, name) {
    super(props, name ? name : "connectivity-overview")

    this.state = {
      connectivityGraphView: Settings.getSetting("connectivityGraphView", "total"),
    }
    this.selectGraph = this.selectGraph.bind(this)
  }

  getLayout() {
    return LAYOUT_CONTENT_BOTTOM_RIGHT
  }

  selectGraph(graph) {
    Settings.setSetting("connectivityGraphView", graph)
    this.setState({ connectivityGraphView: graph })
  }

  renderMainView() {
    return <ProjectsTable projects={this.props.projects} connectivity={this.props.connectivity} devices={this.props.devices} selectProject={this.props.selectProject} />
  }

  renderFooter() {
    const aggregation = this.props.connectivity && this.props.connectivity.aggregation ? this.props.connectivity.aggregation : this.props.aggregation
    let dotAggregate = Aggregations.getUnits(aggregation)
    let centered = this.state.connectivityGraphView === "total"

    let eventLine = (
      <EventLineGraph
        readonly={this.isReadOnly()}
        projects={this.props.projects}
        groups={this.props.groups}
        range={this.props.range}
        dotAggregate={dotAggregate}
        type="data"
        centered={centered}
        style={{
          marginLeft: "110px",
          width: "calc(100% - 140px)",
        }}
      />
    )

    return (
      <GraphSelector
        selectedGraph={this.state.connectivityGraphView}
        selectGraph={this.selectGraph}
        graphs={{
          total: {
            graph: (
              <React.Fragment>
                {eventLine}
                <ConnectivityTotalGraph projects={this.props.projects} connectivity={this.props.connectivity} selectRange={this.props.selectRange} />
              </React.Fragment>
            ),
            name: `${t("connectivity.labels.total_data")}`,
          },
          siteData: {
            graph: (
              <React.Fragment>
                {eventLine}
                <ProjectConnectivityGraph projects={this.props.projects} connectivity={this.props.connectivity} />
              </React.Fragment>
            ),
            name: `${t("connectivity.labels.project_data")}`,
          },
        }}
      />
    )
  }

  renderSiderBar() {
    return (
      <React.Fragment>
        <SideBarSection title={`${t("connectivity.labels.daily_average")}`} content={<DailyDataAverage projects={this.props.projects} connectivity={this.props.connectivity} />} />
        <SideBarSection title={`${t("connectivity.labels.site_connectivity")}`} content={<OutageGraph projects={this.props.projects} connectivity={this.props.connectivity} />} />
        <SideBarSection title={`${t("connectivity.labels.data_provider")}`} content={<ProviderBarGraph projects={this.props.projects} connectivity={this.props.connectivity} />} />
      </React.Fragment>
    )
  }
}

class DailyDataAverage extends EcosuiteComponent {
  renderContent() {
    if (this.props.connectivity) {
      let days = (this.props.connectivity ? moment(this.props.connectivity.range.localEndDate).diff(this.props.connectivity.range.localStartDate, "days") : 0) + 1
      let totalData = Object.values(this.props.projects).reduce((total, project) => (total += this.props.connectivity.projects[project.code].total), 0)
      return <div className="sidebar-heading">{prettyBytes(totalData / days)}</div>
    } else {
      return <Loading />
    }
  }
}

import React from "react"
import { Tooltip } from "reactstrap"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import moment from "moment"
import { create, all } from "mathjs"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

import EnergyUtils from "@dashboard/energy/EnergyUtils"

import SourceTableTooltips from "./SourceTableTooltips"
import Utils from "@common/utils/Utils"
import DeviceUtils from "@common/utils/DeviceUtils"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"

const math = create(all)
const { t } = i18n
class SourceTable extends EcosuiteComponent {
  getNormalisedGeneration(reading, size, days) {
    if (reading && size) {
      let norm = math.round(math.divide(math.divide(EnergyUtils.convertToKilo(reading), size), days), 2)
      return norm.toLocaleString("en")
    }
  }

  formatSourceReading(nodeStatus, reading) {
    if (EnergyUtils.isIrradianceDatum(nodeStatus)) {
      return reading || reading === 0 ? EnergyUtils.formatNumberAsKilo(reading) + "/m2" : undefined
    } else if (reading || reading === 0) {
      return EnergyUtils.formatNumberAsKilo(reading)
    }
  }

  getExpected(nodeStatus) {
    if (EnergyUtils.isGeneratingSource(nodeStatus.sourceId)) {
      let sourceId = nodeStatus.sourceId
      return this.props.expectedGeneration && this.props.expectedGeneration.sources && this.props.expectedGeneration.sources[sourceId]
        ? this.props.expectedGeneration.sources[sourceId].expectedGeneration
        : null
    }
  }

  getPredicted(nodeStatus) {
    if (EnergyUtils.isConsumingSource(nodeStatus.sourceId)) {
      let sourceId = nodeStatus.sourceId
      return this.props.predictedConsumption && this.props.predictedConsumption.sources && this.props.predictedConsumption.sources[sourceId]
        ? this.props.predictedConsumption.sources[sourceId].predictedConsumption
        : null
    } else if (EnergyUtils.isGeneratingSource(nodeStatus.sourceId)) {
      let sourceId = nodeStatus.sourceId
      return this.props.predictedGeneration && this.props.predictedGeneration.sources && this.props.predictedGeneration.sources[sourceId]
        ? this.props.predictedGeneration.sources[sourceId].predictedGeneration
        : null
    }
  }

  isShowSource(nodeStatus) {
    switch (EnergyUtils.getDeviceType(nodeStatus)) {
      case "GEN":
      case "INV":
      case "PYR":
        return this.props.showGeneration && !EnergyUtils.getSubDeviceType(nodeStatus)
      case "CON":
      case "LOAD":
      case "MET":
      case "EXP":
        return this.props.showConsumption && !EnergyUtils.getSubDeviceType(nodeStatus)
      default:
        return true
    }
  }

  setTooltipStatus(nodeStatus) {
    this.setState({
      tooltipStatus: nodeStatus,
    })
  }

  getSourcePeak(nodeStatus) {
    if (!EnergyUtils.isIrradianceDatum(nodeStatus)) {
      if (this.props.datums && this.props.datums.sources && this.props.datums.sources[nodeStatus.sourceId]) {
        return EnergyUtils.formatNumberAsKilo(this.props.datums.sources[nodeStatus.sourceId].peakReading)
      }
    }
  }

  renderContent() {
    let days = this.props.readings ? moment(this.props.range.end).diff(moment(this.props.range.start), "days") : 0
    if (!days) days = 1 // cater for zooming into hour
    let otherLoad = 0
    let showOtherLoad = false

    return this.props.nodesStatus ? (
      <div>
        <table>
          <tbody>
            <tr>
              <th id="col-source-id">{t("table_headings.source_id")}</th>
              <th id="col-source-latest-reading">{t("table_headings.latest_reading")}</th>
              <th id="col-source-last-hour">{`${t("table_headings.last_hour")} (kWh)`}</th>
              <th id="col-source-last-24">{`${t("table_headings.latest_24_hrs")} (kWh)`}</th>
              {this.props.showGeneration ? <th id="col-source-dc-size">{`${t("table_headings.dc_size")} (kW)`}</th> : null}
              {this.props.showGeneration ? <th id="col-source-ac-size">{`${t("table_headings.ac_size")} (kW)`}</th> : null}
              {this.props.showConsumption ? <th id="col-source-expected-peak">{`${t("table_headings.expected_peak")} (kW)`}</th> : null}
              {this.props.system ? (
                <React.Fragment>
                  <th id="col-source-peak" className="ranged">
                    {`${t("labels.peak")} (kW)`}
                  </th>
                  <th id="col-source-peak-percent" className="ranged">
                    %
                  </th>
                  <th id="col-source-start" className="ranged">
                    {`${t("labels.start")} (kWh)`}
                  </th>
                  <th id="col-source-end" className="ranged">
                    {`${t("labels.end")} (kWh)`}
                  </th>
                  <th id="col-source-reading" className="ranged">
                    {`${t("labels.reading")} (kWh)`}
                  </th>
                  <th id="col-source-expected" className="ranged">
                    {`${t("labels.expected")} (kWh)`}
                  </th>
                  <th id="col-source-expected-percent" className="ranged">
                    %
                  </th>
                  <th id="col-source-predicted" className="ranged">
                    {`${t("labels.forecast")} (kWh)`}
                  </th>
                  <th id="col-source-predicted-percent" className="ranged">
                    %
                  </th>
                  <th id="col-source-ratio" className="ranged">
                    {`${t("energy.agn")}`}
                  </th>
                </React.Fragment>
              ) : null}
            </tr>
            {Object.values(this.props.nodesStatus).map((nodeStatus) => {
              if (this.isShowSource(nodeStatus)) {
                let sourceReading = this.props.readings && this.props.readings.sources ? this.props.readings.sources[nodeStatus.sourceId] : undefined
                let reading = sourceReading ? sourceReading.reading : undefined
                let deviceDCSize = DeviceUtils.getDeviceDCSize(this.props.system, nodeStatus.sourceId)
                let deviceACSize = DeviceUtils.getDeviceACSize(this.props.system, nodeStatus.sourceId)
                let peakPower = DeviceUtils.getDevicePeakPower(this.props.system, nodeStatus.sourceId)
                let expected = this.getExpected(nodeStatus)
                let predicted = this.getPredicted(nodeStatus)
                let peak = this.getSourcePeak(nodeStatus)
                let peakPercent = DeviceUtils.getDevicePeakPercent(this.props.system, nodeStatus.sourceId, peak)
                let currentForecast = predicted ? EnergyUtils.getCurrentForecast(predicted, this.props.range) : null

                if (EnergyUtils.isConsumptionDatum(nodeStatus)) {
                  otherLoad += reading
                  showOtherLoad = true
                } else if (EnergyUtils.isLoadDatum(nodeStatus)) {
                  otherLoad -= reading
                }

                let expectedGenerationContent = expected ? EnergyUtils.formatNumberAsKilo(expected) : null
                let isIrradianceComplete = EnergyUtils.isIrradianceCompleteForRange(this.props.project, this.props.site, this.props.system, this.props.range)
                if (!isIrradianceComplete && expected) {
                  expectedGenerationContent = (
                    <span>
                      <Icon icon="warning" id={`incomplete-irr-${this.props.project.code}`} />
                      <UncontrolledTooltip placement="right" target={`incomplete-irr-${this.props.project.code}`}>
                        {t("energy.tooltips.expected_gen", { date: EnergyUtils.formatWattHoursAsKiloWattHours(expected) })}
                      </UncontrolledTooltip>
                    </span>
                  )
                }
                return (
                  <React.Fragment key={nodeStatus.nodeId + nodeStatus.sourceId}>
                    <tr
                      id={"project-row-" + nodeStatus.nodeId + nodeStatus.sourceId.replace(/\//g, "-")}
                      onMouseOver={() => this.setTooltipStatus(nodeStatus)}
                      onMouseOut={() => this.setTooltipStatus()}
                    >
                      <td className={nodeStatus.status} id={`source-${nodeStatus.sourceId.replace(/\//g, "-")}`}>
                        {nodeStatus.sourceId}
                        <UncontrolledTooltip
                          style={{ textAlign: "left" }}
                          placement="right"
                          modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
                          target={`source-${nodeStatus.sourceId.replace(/\//g, "-")}`}
                        >
                          <table className="source-details-tooltip">
                            <tbody>
                              <tr>
                                <td className="meta-data">
                                  {t("table_headings.node")}: {nodeStatus.nodeId}
                                  {this.props.metaData && this.props.metaData[nodeStatus.sourceId] && this.props.metaData[nodeStatus.sourceId].deviceInfo
                                    ? Object.keys(this.props.metaData[nodeStatus.sourceId].deviceInfo).map((key) => {
                                        return (
                                          <div key={key}>
                                            {Utils.capitalizeFirstLetter(key)}: {this.props.metaData[nodeStatus.sourceId].deviceInfo[key]}
                                          </div>
                                        )
                                      })
                                    : null}
                                </td>
                                {sourceReading && sourceReading.subDevices
                                  ? Object.keys(sourceReading.subDevices)
                                      .sort()
                                      .map((subDeviceKey) => {
                                        return (
                                          <td key={subDeviceKey}>
                                            <table>
                                              <thead>
                                                <tr>
                                                  <th>{subDeviceKey}</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {Object.keys(sourceReading.subDevices[subDeviceKey]).map((subDeviceProperty) => {
                                                  return (
                                                    <tr key={subDeviceProperty}>
                                                      <td>{subDeviceProperty}</td>
                                                      <td className="numerical">{sourceReading.subDevices[subDeviceKey][subDeviceProperty]}</td>
                                                    </tr>
                                                  )
                                                })}
                                              </tbody>
                                            </table>
                                          </td>
                                        )
                                      })
                                  : null}
                              </tr>
                            </tbody>
                          </table>
                        </UncontrolledTooltip>
                      </td>
                      <td className={nodeStatus.status} style={{ whiteSpace: "nowrap" }} id="source">
                        {moment(nodeStatus.latestDatumDate).format("lll")}
                      </td>
                      <td className={"numerical " + nodeStatus.status} title={this.renderExpectedTitle(nodeStatus, nodeStatus.hourReading, nodeStatus.hourExpected)}>
                        {this.formatSourceReading(nodeStatus, nodeStatus.hourReading)}
                      </td>
                      <td className={"numerical " + nodeStatus.status} title={this.renderExpectedTitle(nodeStatus, nodeStatus.reading, nodeStatus.expected)}>
                        {this.formatSourceReading(nodeStatus, nodeStatus.reading)}
                      </td>
                      {this.props.showGeneration ? <td className={"numerical"}>{deviceDCSize ? EnergyUtils.formatNumber(deviceDCSize) : ""}</td> : null}
                      {this.props.showGeneration ? <td className={"numerical"}>{deviceACSize ? EnergyUtils.formatNumber(deviceACSize) : ""}</td> : null}
                      {this.props.showConsumption ? <td className={"numerical"}>{peakPower ? EnergyUtils.formatNumber(peakPower) : ""}</td> : null}
                      {this.props.system ? (
                        <React.Fragment>
                          <td className="numerical ranged">{peak}</td>
                          <td className="numerical ranged">{peakPercent ? `${peakPercent}%` : ""}</td>
                          <td className="numerical ranged">{sourceReading && sourceReading.start ? this.formatSourceReading(nodeStatus, sourceReading.start) : ""}</td>
                          <td className="numerical ranged">{sourceReading && sourceReading.end ? this.formatSourceReading(nodeStatus, sourceReading.end) : ""}</td>
                          <td className="numerical ranged">{this.formatSourceReading(nodeStatus, reading)}</td>
                          <td className="numerical ranged">{expectedGenerationContent}</td>
                          <td className="numerical ranged">{isIrradianceComplete && expected && reading ? math.round((reading / expected) * 100) : null}</td>
                          <td className="numerical ranged">{predicted ? EnergyUtils.formatNumberAsKilo(predicted) : null}</td>
                          <td className="numerical ranged" title={`${t("energy.labels.based_on_forecast")}: ${EnergyUtils.displayWattHours(currentForecast)}`}>
                            {currentForecast && reading ? math.round((reading / currentForecast) * 100) : null}
                          </td>
                          <td className="numerical ranged">{this.getNormalisedGeneration(reading, deviceDCSize, days)}</td>
                        </React.Fragment>
                      ) : null}
                    </tr>
                  </React.Fragment>
                )
              }
              return null
            })}

            {showOtherLoad ? this.getOtherLoadRow(otherLoad, days) : null}
          </tbody>
        </table>

        <SourceTableTooltips {...this.props} />
        {this.renderTooltip()}
      </div>
    ) : (
      <Loading />
    )
  }

  renderExpectedTitle(nodeStatus, actual, expected) {
    let actualReading = this.formatSourceReading(nodeStatus, actual)
    let expectedReading = this.formatSourceReading(nodeStatus, expected)
    return (
      t("labels.actual") +
      " " +
      (actualReading ? actualReading : "-") +
      `${t("labels.expected")} ` +
      (expectedReading ? expectedReading : "-") +
      " (" +
      (expected && actual ? math.round((actual / expected) * 100) : "-") +
      "%)"
    )
  }

  renderTooltip() {
    let nodeStatus = this.state.tooltipStatus

    if (nodeStatus && nodeStatus.causes && nodeStatus.causes.length > 0) {
      let id = "project-row-" + nodeStatus.nodeId + nodeStatus.sourceId.replace(/\//g, "-")
      return (
        <Tooltip
          placement="top"
          modifiers={[{ name: "preventOverflow", options: { boundariesElement: "viewport" } }]}
          isOpen={nodeStatus !== undefined}
          target={id}
          className={"tooltip-" + nodeStatus.status}
        >
          {nodeStatus.causes.map((cause) => {
            return (
              <div key={cause.message}>
                {nodeStatus.sourceId} {cause.message}
              </div>
            )
          })}
        </Tooltip>
      )
    }
  }

  getSytemSourcePrefix() {
    return "/" + this.props.project.code + "/" + this.props.site.code + "/" + this.props.system.code
  }

  getOtherLoadRow(otherLoad, days) {
    if (this.props.system && this.props.showConsumption) {
      // We a virtual LOAD/OTHER source ID for the load that isn't being tracked by a SolarNetwork node
      let otherLoadSourceId = this.getSytemSourcePrefix() + "/LOAD/OTHER"
      let size = DeviceUtils.getDeviceDCSize(otherLoadSourceId)
      let acSize = DeviceUtils.getDeviceACSize(otherLoadSourceId)
      let peakPower = DeviceUtils.getDevicePeakPower(otherLoadSourceId)
      let expected = this.getExpected({ sourceId: otherLoadSourceId })

      return (
        <tr key={"project-row-" + otherLoadSourceId}>
          <td>{otherLoadSourceId}</td>
          <td />
          <td />
          <td />
          <td className={"numerical"}>{size ? EnergyUtils.formatNumber(size) : ""}</td>
          <td className={"numerical"}>{acSize ? EnergyUtils.formatNumber(acSize) : ""}</td>
          <td className={"numerical"}>{peakPower ? EnergyUtils.formatNumber(peakPower) : ""}</td>

          <td className="numerical ranged">{EnergyUtils.formatWattHoursAsKiloWattHours(otherLoad)}</td>
          <td className="numerical ranged">{expected ? EnergyUtils.formatWattHoursAsKiloWattHours(expected) : null}</td>
          <td className="numerical ranged">{expected && otherLoad ? math.round((otherLoad / expected) * 100) : null}</td>
          <td className="numerical ranged">{this.getNormalisedGeneration(otherLoad, size, days)}</td>
        </tr>
      )
    }
  }
}

export default SourceTable

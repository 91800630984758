import React from "react"
import { Table, DataType, SortingMode, SortDirection } from "ka-table"
import { DocumentItem, useGetOnboardingDocuments, useGetUserOnboardingData } from "src/services/onboarding"
import { H4 } from "src/components/ui/typography"
import WheelLoader from "./Loader"
import { Spinner } from "src/components/ui/spinner"
import { Button } from "src/components/ui/button"
import { DownloadIcon, EyeOpenIcon } from "@radix-ui/react-icons"
import { useFilePreview, PreviewDialog, PreviewFile, downloadFile, viewFile } from "../Components/Preview"
import { toast } from "react-toastify"

const calculateDocumentProgress = (documentAge: number, isDone: boolean): number => {
  if (isDone) return 1

  const ageInMinutes = documentAge / (1000 * 60)
  const maxProgress = 0.99 // Max progress for a single document unless marked done

  // Rapid initial increase function
  const initialProgress = Math.min(0.2, ageInMinutes / 60) // 20% in the first hour, linear

  // Slower long-term progress function
  const longTermProgress = 0.79 * (1 - Math.exp(-ageInMinutes / (24 * 60))) // Remaining 79% over longer period

  return maxProgress * (initialProgress + longTermProgress)
}

const calculateProgress = (documents: DocumentItem[], markedDone: string[]): number => {
  if (documents.length === 0) return 0

  const now = new Date().getTime()
  const progressPerDocument = 100 / documents.length

  let totalProgress = 0

  documents.forEach((doc) => {
    const documentAge = now - new Date(doc.updated).getTime()
    const isDone = markedDone.includes(doc.fileKey)
    const documentProgress = calculateDocumentProgress(documentAge, isDone)
    totalProgress += documentProgress * progressPerDocument
  })

  // Round to one decimal place
  return Math.round(totalProgress * 10) / 10
}

const ActionButtons: React.FC<{
  rowData: DocumentItem
}> = ({ rowData }) => {
  const [loadingDownload, setLoadingDownload] = React.useState(false)

  const { previewFile, setPreviewFile, pdfLoadError, handlePreview, isLoading: loadingPreview } = useFilePreview()

  const handleDownload = async (file: PreviewFile) => {
    try {
      setLoadingDownload(true)
      const url = await viewFile(file.fileKey!)
      await downloadFile(url, file.fileName!)
      toast.success("Succesfully downloaded file! Check your downloads folder.")
    } catch (error) {
      toast.error("Download failed!")
    } finally {
      setLoadingDownload(false)
    }
  }

  return (
    <>
      <PreviewDialog
        previewFile={previewFile}
        setPreviewFile={setPreviewFile}
        pdfLoadError={pdfLoadError}
        isLoading={loadingPreview}
      />
      <div className="tw-flex">
        <Button
          size="sm"
          variant="outline"
          className="tw-p-2"
          disabled={loadingPreview}
          onClick={() => handlePreview({ fileName: rowData.name, fileKey: rowData.fileKey })}
        >
          {loadingPreview ? <Spinner className="tw-h-4 tw-w-4" /> : <EyeOpenIcon className="tw-h-4 tw-w-4" />}
        </Button>
        <Button
          size="sm"
          variant="outline"
          className="tw-p-2 tw-ml-2"
          disabled={loadingDownload}
          onClick={() => handleDownload({ fileName: rowData.name, fileKey: rowData.fileKey })}
        >
          {loadingDownload ? <Spinner className="tw-h-4 tw-w-4" /> : <DownloadIcon className="tw-h-4 tw-w-4" />}
        </Button>
      </div>
    </>
  )
}

export const OnboardingDocumentsTable: React.FC = () => {
  const { data, isLoading } = useGetOnboardingDocuments()

  if (isLoading) {
    return (
      <div className="tw-py-16">
        <Spinner />
      </div>
    )
  }

  const tableData =
    data?.documents.map((doc) => ({
      fileKey: doc.fileKey,
      name: doc.name,
      updated: new Date(doc.updated).toLocaleString(),
    })) || []

  return (
    <div className="twx-ka-table tw-pt-4">
      <Table
        columns={[
          { key: "action", title: "Actions", dataType: DataType.String, width: 160 },
          { key: "name", title: "Document Name", dataType: DataType.String },
          {
            key: "updated",
            title: "Last Updated",
            dataType: DataType.String,
            width: 280,
            sortDirection: SortDirection.Ascend,
          },
        ]}
        virtualScrolling={{ enabled: true }}
        noData={{ text: "No documents found! Please upload documents from upload tab!" }}
        data={tableData}
        rowKeyField="fileKey"
        sortingMode={SortingMode.Single}
        childComponents={{
          cellText: {
            content: (props) => {
              if (props.column.key === "action") {
                return <ActionButtons rowData={props.rowData} />
              }
              return props.value
            },
          },
          tableWrapper: {
            elementAttributes: () => ({ style: { maxHeight: "calc(100vh - 580px)" } }),
          },
        }}
      />
    </div>
  )
}

export default function ListMyDocuments() {
  const { data, isLoading } = useGetOnboardingDocuments()
  const { data: metaData } = useGetUserOnboardingData()

  const progress = calculateProgress(data?.documents || [], metaData?.markedDone || [])

  if (isLoading) {
    return (
      <div className="public-view tw-bg-background tw-text-foreground tw-h-screen tw-flex tw-items-center tw-justify-center">
        <Spinner size="large">Loading...</Spinner>
      </div>
    )
  }

  const userHasDocuments = data?.documents.length

  return (
    <div>
      <H4>
        {userHasDocuments
          ? "Your uploaded documents are being distilled. Your org will be set-up automatically from these distilled data!"
          : "Please upload your documents! Uploaded documents will be distilled and your org will be set-up automatically from these distilled data"}
      </H4>
      <div className="tw-mt-8 lg:tw-mt-32">
        <WheelLoader progress={Number(progress.toFixed(2))} notStarted={!userHasDocuments} />
      </div>
      <H4 className="tw-mt-8">Your uploaded documents</H4>
      <OnboardingDocumentsTable />
    </div>
  )
}

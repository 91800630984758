import React from "react"
import { Alert, FormGroup, Input, Label } from "reactstrap"
import Icon from "@common/display/Icon"
import { SECURE_GROUPS } from "src/App"
import i18n from "src/i18n"

const { t } = i18n
// Checkbox widget override that displays CognitoGroups in a list
const UserGroupSelector = ({ id, schema, options, ...props }) => {
  // Currently only Array Type Schema Elements can be checkboxes
  if (schema.type !== "array") {
    return undefined
  }

  // Get group order from schema
  const categoryOrder = schema.categoryOrder

  // Separate the CognitoGroups into their individual categories by converting string to object notation.
  // ex: "Product: Generation" => { Product: [{ label: "Generation", value: "generation" }] }
  const categories = {}
  options.enumOptions?.map((option) => {
    const [groupType, groupDescription] = option.label.split(": ")
    if (groupDescription) {
      categories[groupType] = categories[groupType] ?? []
      categories[groupType].push({ label: groupDescription, value: option.value })
    } else {
      categories.admin = categories.admin ?? []
      categories.admin.push({ label: groupType, value: option.value })
    }
  })

  return (
    <>
      {/* Render Title and Warning */}
      <Alert color="info" className="info ecogy-form__default-permissions__info">
        <Icon icon="info" />
        {schema.items.description}
      </Alert>
      <h2 id={id}>{schema.items.title}</h2>
      <hr />

      {/* Sort Categories and render title with checkboxes */}
      {Object.keys(categories)
        .sort((a, b) => categoryOrder.indexOf(a.toLowerCase()) - categoryOrder.indexOf(b.toLowerCase()))
        .map((category) => {
          return (
            <div key={`${category}`}>
              <h4 style={{ textTransform: "capitalize" }}>{category}</h4>

              <table className="user-groups">
                <tbody>
                  {categories[category].map((option, idx) => {
                    return (
                      <tr key={`${option}_${idx}`}>
                        <td className="user-group-name">
                          <FormGroup check>
                            <Input
                              type="checkbox"
                              value={option.value}
                              checked={props.value.includes(option.value)}
                              onChange={() => {
                                if (props.value.includes(option.value)) {
                                  // make a copy of the value prop to avoid direct mutations
                                  const valuePropCopy = [...props.value]
                                  valuePropCopy.splice(props.value.indexOf(option.value), 1)
                                  props.onChange(valuePropCopy)
                                } else {
                                  props.onChange([...props.value, option.value])
                                }
                              }}
                            />
                            <Label check>{option.value}</Label>
                          </FormGroup>
                        </td>
                        <td>{`${option.label}${SECURE_GROUPS.includes(option.value) ? `${t("settings.messages.will_require_MFA")}` : ""}`}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        })}
    </>
  )
}

export default UserGroupSelector

import React from "react"
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell } from "recharts"

import prettyBytes from "src/pretty-bytes"

import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"

class ProviderBarGraph extends EcosuiteComponent {
  render() {
    if (this.props.connectivity) {
      let data = []
      this.props.connectivity.providers.forEach((provider, idx) => {
        data[idx] = {
          name: provider.name,
          bytes: this.props.projects.reduce((total, project) => {
            return (total += provider.projectTotals[project.code])
          }, 0),
        }
      })

      return (
        <div className="sidebar-graph">
          <BarChart width={300} height={300} data={data} margin={{ top: 5, right: 5, bottom: 5, left: 25 }}>
            <XAxis dataKey="name" />
            <YAxis tickFormatter={prettyBytes} />
            <Tooltip
              wrapperStyle={{ color: "#000" }}
              // eslint-disable-next-line no-unused-vars
              formatter={(value, name, props) => {
                return prettyBytes(value)
              }}
            />
            <Bar dataKey="bytes" fill={GRAPH_COLORS[0]}>
              {this.props.connectivity.providers.map((entry, index) => (
                <Cell key={index} fill={GRAPH_COLORS[index % GRAPH_COLORS.length]} />
              ))}
            </Bar>
          </BarChart>
        </div>
      )
    } else {
      return <Loading />
    }
  }
}

export default ProviderBarGraph

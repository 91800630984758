import React from "react"

import SettingsService from "../SettingsService"
import EcosuiteForm from "@common/form/EcosuiteForm"
import { Error, Loading } from "@common/EcosuiteComponent"
import { ParameterStoreArrayTemplate } from "./ParameterStoreWidget"
import ParameterStoreHooks from "./ParameterStoreHooks"

import { JSONSchema7, JSONSchema7Object, validate } from "json-schema"
import type { ParamaeterStoreFormData, ParameterStorePathResponse } from "@ecogy-types/openapi-types"
import type { Nullable, typeofArrayElements } from "@ecogy-types/utility-types"
import i18n from "src/i18n"

const { t } = i18n
// The json-schema validator doesn't remove empty strings so adding null to the type definition allows
// empty strings to be converted into null without typescript complaining about type mismatches.
export type FormData = Nullable<typeofArrayElements<ParamaeterStoreFormData>> & { lastModified?: typeofArrayElements<ParameterStorePathResponse>["LastModifiedDate"] }

export const ParameterStore = (): JSX.Element => {
  const { formSchema, formData, setFormData, error } = ParameterStoreHooks()

  if (error) {
    return <Error error={error} />
  }

  if (!formSchema || !formData) {
    return <Loading />
  }

  return (
    <div className="Admin__parameter-store">
      {/* Typescript doesn't like our current implementation of this component */}
      {/* @ts-ignore */}
      <EcosuiteForm
        schema={formSchema as JSONSchema7}
        formData={formData}
        ArrayFieldTemplate={ParameterStoreArrayTemplate}
        formContext={{
          updateFormData: (data: FormData) => setFormData(formData.map((original) => (original.path === data.path ? data : original))),
          validateData: (data: FormData) => {
            const { errors } = validate(data, formSchema.items as JSONSchema7Object)

            // Make errors more readable
            return errors.reduce(
              (e, error) => {
                let errorMessage = error.message
                if (error.message.includes("required")) {
                  errorMessage = `${t("errors.required_field")}`
                }

                return { ...e, [error.property]: errorMessage }
              },
              {} as Record<string, string>,
            )
          },
          putParameter: (data: FormData) => {
            SettingsService.putStoreParam(data)

            // Optimistically update the formData with the changes
            setFormData(formData.map((datum) => (datum.path === data.path ? { ...data, path: `/Settings/${data.paramName}` } : datum)))
          },
        }}
      >
        <>{/* Ecogy Form renders a submit button if it has no children, this empty fragment prevents that*/}</>
      </EcosuiteForm>
    </div>
  )
}

import React, { useState } from "react"
import { Table, Input } from "reactstrap"

export const ChecklistField = ({ id, schema, formData, ...props }) => {
  // Don't render if the schema entry has no children
  if (Object.keys(schema.properties).length === 0) {
    return null
  }

  // TODO: Consolidate the state into a single object
  const [showRecordInput, setShowRecordInput] = useState(false)
  const [selectedRecordInput, setSelectedRecordInput] = useState("")
  const [showDataInput, setShowDataInput] = useState(false)
  const [selectedDataInput, setSelectedDataInput] = useState("")
  const [showNotesInput, setShowNotesInput] = useState(false)
  const [selectedNotesInput, setSelectedNotesInput] = useState("")

  const sortedEntries = schema["ui:order"] ? sortEntries(schema) : Object.entries(schema.properties)

  function sortEntries(schema) {
    return [...Object.entries(schema.properties)].sort((a, b) => {
      return schema["ui:order"].indexOf(a[0]) > schema["ui:order"].indexOf(b[0]) ? 1 : -1
    })
  }

  //Only compare visable elements when deciding the Table (heading) checkbox state
  let toCheck = []
  Object.keys(schema.properties).forEach((key) => {
    if (formData[key] !== undefined) {
      toCheck.push(formData[key])
    }
  })

  return (
    <div className={"border border-1 p-2"}>
      <Table id={id} dark striped>
        <thead>
          <tr>
            <th className="table__header">{schema.title}</th>
            <th className="table__header-placeholder table__check-container" style={{ width: "5%" }}>
              <div className="table__check">
                <Input type="checkbox" checked={toCheck.every((child) => child.isChecked)} disabled />
              </div>
            </th>
            <th className={"table__header-placeholder"}>Record</th>
            <th className={"table__header-placeholder"}>Data</th>
            <th className={"table__header-placeholder"}>Notes</th>
          </tr>
        </thead>
        <tbody>
          {sortedEntries.map(([key, child]) => {
            return (
              <tr key={child.title}>
                {/*   Title   */}
                <td>
                  <span>{child.title}</span>
                </td>

                <td className="table__check-container">
                  <div className="table__check">
                    <Input
                      type="checkbox"
                      checked={formData[key]?.isChecked}
                      onChange={(e) => {
                        props.onChange({
                          ...formData,
                          [key]: { ...formData[key], isChecked: e.target.checked },
                        })
                      }}
                    />
                  </div>
                </td>

                {/*   Record   */}
                <td
                  className={`table__text-container  ${selectedRecordInput === key ? "no-padding" : ""}`}
                  onClick={() => {
                    setSelectedRecordInput(key)
                    setShowRecordInput(true)
                  }}
                >
                  <div className="table__text">
                    {showRecordInput && selectedRecordInput === key ? (
                      <Input
                        autoFocus
                        type="text"
                        value={formData[key]?.record ?? ""}
                        onChange={(e) => {
                          props.onChange({
                            ...formData,
                            [key]: { ...formData[key], record: e.target.value },
                          })
                        }}
                        onBlur={() => {
                          setSelectedRecordInput("")
                          setShowRecordInput(false)
                        }}
                        onKeyDown={(e) => {
                          // This trick prevents the Form from submitting
                          // if Enter is pressed while editing the note
                          if (e.key === "Enter") e.target.blur()
                        }}
                      />
                    ) : (
                      <span>{formData[key]?.record}</span>
                    )}
                  </div>
                </td>

                {/*   Data   */}
                <td
                  className={`table__text-container  ${selectedDataInput === key ? "no-padding" : ""}`}
                  onClick={() => {
                    setSelectedDataInput(key)
                    setShowDataInput(true)
                  }}
                >
                  <div className="table__text">
                    {showDataInput && selectedDataInput === key ? (
                      <Input
                        autoFocus
                        type="text"
                        value={formData[key]?.data ?? ""}
                        onChange={(e) => {
                          props.onChange({
                            ...formData,
                            [key]: { ...formData[key], data: e.target.value },
                          })
                        }}
                        onBlur={() => {
                          setSelectedDataInput("")
                          setShowDataInput(false)
                        }}
                        onKeyDown={(e) => {
                          // This trick prevents the Form from submitting
                          // if Enter is pressed while editing the note
                          if (e.key === "Enter") e.target.blur()
                        }}
                      />
                    ) : (
                      <span>{formData[key]?.data}</span>
                    )}
                  </div>
                </td>

                {/*   Notes   */}
                <td
                  className={`table__text-container  ${selectedNotesInput === key ? "no-padding" : ""}`}
                  onClick={() => {
                    setSelectedNotesInput(key)
                    setShowNotesInput(true)
                  }}
                >
                  <div className="table__text">
                    {showNotesInput && selectedNotesInput === key ? (
                      <Input
                        autoFocus
                        type="text"
                        value={formData[key]?.notes ?? ""}
                        onChange={(e) => {
                          props.onChange({
                            ...formData,
                            [key]: { ...formData[key], notes: e.target.value },
                          })
                        }}
                        onBlur={() => {
                          setSelectedNotesInput("")
                          setShowNotesInput(false)
                        }}
                        onKeyDown={(e) => {
                          // This trick prevents the Form from submitting
                          // if Enter is pressed while editing the note
                          if (e.key === "Enter") e.target.blur()
                        }}
                      />
                    ) : (
                      <span>{formData[key]?.notes}</span>
                    )}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}
